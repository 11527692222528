import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import SchoolFilter from "./elgibility-questions/school-filter";
import GroupFilter from "./elgibility-questions/group-filter";
import CitizenFilter from "./elgibility-questions/citizen-filter";

//section form fillouts
import PersonalData from "./section-2/personal-data";

import FamilySize from "./section-2/family-size";

import { FaTasks, FaFilePdf, FaWpforms, FaCalculator } from "react-icons/fa";
import StemInterest from "./section-2/stem-interest";
import FosterYouth from "./section-2/foster-youth";
import AcademicNeed from "./section-2/academic-need";
import PublicAssitance from "./section-2/public-assistance";
import Gpa from "./section-2/gpa";
import Grade from "./section-2/grade";
import FamilyIncome from "./section-2/family-income";

import axios from "axios";

import eligForm from "../../assets/program-overview/student-eligib-form.pdf";
import trioLogo from "../../assets/logos/trio_logo.jpg";

import { withFirebase } from "../../components/firebase/fb-index";
const initialState = {
  currentStep: 1, // Default is Step 1
  showCalculator: null,
  showDisqualification: null,
  disable: true,
  showPDF: false,

  //pre-qualification
  group: null,
  citizen: null,

  //items to submit to sheet
  school: null,

  //personal information input boxes
  studentName: null,
  studentEmail: null,
  studentPhone: null,
  parentName: null,
  parentEmail: null,
  parentPhone: null,

  stemInterest: null,
  fosterYouth: null,
  publicAssitance: null,
  gpa: null,
  grade: null,
  familySize: null,
  familyIncome: null,
  academicNeed: null,
};

class FormController extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = initialState;

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.handleEligibilityFormOpen = this.handleEligibilityFormOpen.bind(this);
    this.handleEligibilityFormClose = this.handleEligibilityFormClose.bind(
      this
    );
    this.handleCloseDisqualification = this.handleCloseDisqualification.bind(
      this
    );
    this.handleClosePDF = this.handleClosePDF.bind(this);
  }

  // Use the submitted data to set the state
  handleChange(event) {
    // console.log("Event", event);
    // console.log("Event Target Name", event.target.name);
    // console.log("Event Target Value", event.target.value);
    const { name, value } = event.target;

    if (this.state.currentStep === 5) {
      this.setState({
        [name]: value,
      });
      this.checkAllFields();
    } else {
      this.setState({
        [name]: value,
        disable: false,
      });
    }
    //check if all fields are validated for the personal-data
  }

  checkAllFields() {
    if (
      this.state.studentEmail !== null &&
      this.state.studentName !== null &&
      this.state.studentPhone !== null &&
      this.state.parentPhone !== null &&
      this.state.parentName !== null &&
      this.state.parentEmail !== null
    ) {
      this.setState({
        disable: false,
      });
    }
  }

  // Trigger an alert on form submission
  handleSubmit = (event) => {
    event.preventDefault();

    let addToSheet = [
      this.state.studentName,
      this.state.studentEmail,
      this.state.studentPhone,
      this.state.school,
      this.state.parentName,
      this.state.parentEmail,
      this.state.parentPhone,
      this.state.familyIncome,
      this.state.familySize,
      this.state.citizen,
      this.state.gpa,
      this.state.grade,
      this.state.group,
      this.state.stemInterest,
      this.state.fosterYouth,
      this.state.publicAssistance,
      this.state.academicNeed,
    ];
    let opt = {
      method: "POST",
      url: "https://us-central1-ububuntu-889ef.cloudfunctions.net/eligibility",
      data: addToSheet,
    };

    axios.post(opt.url, opt.data).then((res) => {
      // console.log(res);
    });

    this.setState({
      showPDF: true,
    });
  };

  showDisqualificationMessage() {
    this.setState({
      showCalculator: false,
      showDisqualification: true,
    });
  }

  async checkEligibility(step) {
    switch (step) {
      case 1:
        if (this.state.school === "None of the above") {
          this.showDisqualificationMessage();
          this.setState({
            currentStep: 1,
          });
        }

        break;
      case 2:
        // console.log("CHEKCING ELIGIBILITY TO MEET GROUP REQUIREMENT");
        if (this.state.group === "yes") {
          this.showDisqualificationMessage();
          this.setState({
            currentStep: 1,
          });
        }
        break;
      case 3:
        // console.log("CHEKCING ELIGIBILITY TO MEET ------------ citizenship requirement");
        if (this.state.citizen === "no") {
          this.showDisqualificationMessage();
          this.setState({
            currentStep: 1,
          });
        }
        break;
      default:
        return;
    }
  }

  // normalizer function to remove periods, $, and commas from income value inputted by user
  normalizeFamilyIncome() {
    //remove $ if included
    let normalizedIncome = this.state.familyIncome;
    if (normalizedIncome.includes("$")) {
      normalizedIncome = normalizedIncome.replace("$", "");
    }
    if (normalizedIncome.includes(",")) {
      normalizedIncome = normalizedIncome.replace(",", "");
    }

    if (normalizedIncome.includes(".")) {
      normalizedIncome = Math.round(parseFloat(normalizedIncome));
    } else {
      normalizedIncome = parseInt(normalizedIncome);
    }

    return normalizedIncome;
  }

  // if family size is greater than 8 then the case is defaulted; add 6720 to the allowable income for every additonal family member
  handleDefaultIncome() {
    let additionalFamily = this.state.familySize - 8;
    let additionalIncome = additionalFamily * 6720;
    let allowableIncome = 66180 + additionalIncome;

    if (this.state.familyIncome <= allowableIncome) {
      return true;
    } else {
      return false;
    }
  }

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;
    this.checkEligibility(currentStep);
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 11 ? 12 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
      disable: true,
      passIncomeCheck: null,
    });
  }
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  // The "next" and "previous" button functions
  previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button
          onClick={this._prev}
          style={{ backgroundColor: "white", color: "red" }}
        >
          <strong>Previous</strong>
        </Button>
      );
    }
    // ...else return nothing
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 6, then render the "next" button
    if (currentStep < 12) {
      return (
        <Button
          className="float-right"
          type="button"
          onClick={this._next}
          id="nextButton"
          disabled={this.state.disable}
          style={{ backgroundColor: "black", color: "white" }}
        >
          <strong> Next</strong>
        </Button>
      );
    } else {
      return (
        <Button
          className="float-right"
          type="button"
          onClick={this.handleSubmit}
          id="nextButton"
          style={{ backgroundColor: "black", color: "white" }}
        >
          <strong> Submit</strong>
        </Button>
      );
    }
  }

  filterType() {
    let step = this.state.currentStep;

    // console.log("Current State: ", this.state);
    switch (step) {
      case 1:
        return (
          <SchoolFilter currentStep={step} handleChange={this.handleChange} />
        );
      case 2:
        return (
          <GroupFilter currentStep={step} handleChange={this.handleChange} />
        );
      case 3:
        return (
          <CitizenFilter currentStep={step} handleChange={this.handleChange} />
        );
      case 4:
        return (
          <PersonalData currentStep={step} handleChange={this.handleChange} />
        );
      case 5:
        return (
          <FamilySize currentStep={step} handleChange={this.handleChange} />
        );
      case 6:
        return (
          <FamilyIncome currentStep={step} handleChange={this.handleChange} />
        );

      case 7:
        return (
          <StemInterest
            currentStep={step}
            handleChange={this.handleChange}
          ></StemInterest>
        );
      case 8:
        return (
          <FosterYouth
            currentStep={step}
            handleChange={this.handleChange}
          ></FosterYouth>
        );
      case 9:
        return (
          <AcademicNeed currentStep={step} handleChange={this.handleChange} />
        );

      case 10:
        return (
          <PublicAssitance
            currentStep={step}
            handleChange={this.handleChange}
          />
        );
      case 11:
        return <Gpa currentStep={step} handleChange={this.handleChange} />;
      case 12:
        return <Grade currentStep={step} handleChange={this.handleChange} />;
      default:
        return;
    }
  }

  handleEligibilityFormOpen() {
    this.setState({
      showCalculator: true,
    });
  }
  handleEligibilityFormClose() {
    this.setState({
      showCalculator: false,
      currentStep: 1,
    });
  }

  handleCloseDisqualification() {
    this.setState({
      showDisqualification: false,
      currentStep: 1,
    });
  }

  handleClosePDF() {
    this.setState({
      showPDF: false,
      currentStep: 1,
    });
  }
  render() {
    return (
      <>
        <div>
          <FaCalculator
            style={{
              color: "red",
              cursor: "pointer",
              fontSize: "0.95em",
              display: "flex",
            }}
            onClick={this.handleEligibilityFormOpen}
          ></FaCalculator>
        </div>
        <Modal
          size="md"
          show={this.state.showCalculator}
          onHide={this.handleEligibilityFormClose}
          style={{
            backgroundColor: "black",
          }}
        >
          <Modal.Header closeButton style={{ backgroundColor: "white" }}>
            <Modal.Title>
              {" "}
              <img
                src={trioLogo}
                style={{ height: "2em", width: "2em" }}
                alt="trio-logo"
              ></img>
              <strong> Eligibility Calculator</strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{
              height: "auto",
              justifyContent: "center",
            }}
          >
            <React.Fragment>
              <Form
                onSubmit={this.handleSubmit}
                style={{
                  textAlign: "center",
                }}
              >
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {this.filterType()}
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                {this.previousButton()}
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                {this.nextButton()}
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={this.state.showDisqualification}
          onHide={this.handleCloseDisqualification}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <img
                src={trioLogo}
                style={{ height: "2em", width: "2em" }}
                alt="trio-logo"
              ></img>{" "}
              Eligibility Calculator
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <React.Fragment>
              You do not meet the minimum requirements
            </React.Fragment>
          </Modal.Body>

          <Modal.Footer>
            <Button
              style={{ backgroundColor: "red" }}
              onClick={this.handleCloseDisqualification}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size="lg" show={this.state.showPDF} onHide={this.handleClosePDF}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <img
                src={trioLogo}
                style={{ height: "2em", width: "2em" }}
                alt="trio-logo"
              ></img>{" "}
              Upward Bound Online Application Form{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <React.Fragment>
              You qualify to a be a student of Upward Bound! Please fill out our
              online eligibility form by clicking on the button below. If you
              need to fill it out at a later time, the eligibility form is also
              available under the <a href="/services/#links">Resources tab</a>{" "}
              on the Services Page.{" "}
              <strong>
                {" "}
                Download the fillable pdf form and submit to
                upwardboundlbcc@gmail.com
              </strong>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer
            style={{
              justifyContent: "center",
              alignItems: "center",
              color: "black",
            }}
          >
            <a href="https://drive.google.com/file/d/17b8UvZxa3sFPWTCgsJAZwkqF6OZ7GZ4l/view?usp=sharing">
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FaWpforms size={30} style={{ color: "red" }}></FaWpforms>
              </Row>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  color: "black",
                }}
              >
                Eligibility Form
              </Row>
            </a>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withFirebase(FormController);
