import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import surveys from "./survey-info.json";
class Surveys extends React.Component {
  render() {
    return (
      <div>
        <Row
          style={{
            padding: "1em 0em 2em 0em",
            display: "flex",
            alignItems: "baseline",
          }}
        >
          {surveys.surveys.map((item) => {
            return (
              <Col xs={12} sm="12" md="5" lg="4" style={{ padding: "1em" }}>
                <Card>
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.text}</Card.Text>
                    <Card.Link href={item.link}>Take Survey</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default Surveys;
