import React from "react";
import "./tabs-view.css";

class TabsSection extends React.Component {
  constructor(props) {
    super(props);

    // console.log("PROPERTIES HANDED TO TAB SECTION", this.props);

    this.openTab = this.openTab.bind(this);
    this.flipButton = this.flipButton.bind(this);
  }
  componentDidMount() {
    this.openTab(
      this.props.tabinfo.descriptions[0].tid,
      this.props.tabinfo.descriptions[0].button
    );
    // window.scrollTo(0, 0);
  }

  openTab(tab, tabButton) {
    let i;
    // console.log("TAB CONTENT CLASS NAME: ", this.props.tabinfo);
    let tabs = Array.from(
      document.getElementsByClassName(
        this.props.tabinfo.sectionTabContentClassName
      )
    );
    for (i = 0; i < tabs.length; i++) {
      tabs[i].style.display = "none";
    }

    this.flipButton(tabButton);

    let x = document.getElementById(tab);
    if (x !== null) {
      x.style.display = "block";
    }
  }

  flipButton(tabButton) {
    //grab all tab buttons by the tab-title-fixed css class name
    let x = Array.from(document.getElementsByClassName("tab-title-fixed"));

    // update each element to make sure they are NOT selected
    x.forEach((element) => {
      element.className = "tab-button-not-selected-fixed tab-title-fixed";
    });

    // get the button that has the tab button class name & make it the selected button css
    let y = document.getElementById(tabButton);
    if (y !== null) {
      y.className = "tab-button-selected-fixed tab-title-fixed";
    }
  }

  render() {
    return (
      /* BUTTON FOR EACH SECTION */
      <div className="section-overview-tabs">
        {this.props.tabinfo.descriptions.map((item, index) => {
          return (
            <button
              id={item.button}
              className="tab-button-not-selected-fixed tab-title-fixed"
              onClick={() => this.openTab(item.tid, item.button)}
              key={item.button}
            >
              {item.title}
            </button>
          );
        })}
      </div>
    );
    {
      /*  CONTENT FOR EACH TAB */
    }
    {
      /* <div>
          {this.props.tabinfo.descriptions.map((item, index) => {
            return (
              <div
                id={item.tid}
                className={this.props.tabinfo.sectionTabContentClassName}
                key={item.id}
              >
                <div className="tab-container section-overview-tabconent">
                  <div className="tab-section">
                    <div className="tab-sub-heading">
                      <h4>{item.title}</h4>
                      <hr />
                    </div>
                    <div>{item.text}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div> */
    }
  }
}
export default TabsSection;
