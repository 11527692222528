import React from "react";
import axios from "axios";

// IMPORT COTS
import { Table } from "react-bootstrap";
import { FaLink } from "react-icons/fa";

class GSLinksTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };
  }

  componentDidMount() {
    console.log(" Logging Getting Method to Google Sheet");
    let opt = {
      method: "GET",
      url: "https://us-central1-ububuntu-889ef.cloudfunctions.net/meetinglinks",
    };

    axios.get(opt.url).then((res) => {
      console.log(res.data);
      this.setState({
        links: res.data,
      });
    });
  }

  splitResource(resource) {
    if (resource !== undefined) {
      console.log(resource);
      resource = resource.split(/\r?\n/);
      console.log(resource);

      return resource.map((item, index) => {
        return <p>{item}</p>;
      });
    }
  }

  render() {
    if (this.state.links.length !== 0) {
      return (
        <div id="gs-links-table">
          <Table responsive="md" hover>
            <thead>
              {this.state.links[0].map((header, index) => {
                return <th>{header}</th>;
              })}
            </thead>
            <tbody>
              {this.state.links.map((resource, index) => {
                if (index === 0) {
                  return null;
                } else {
                  return (
                    <tr>
                      {/* Title with Link */}
                      <td style={{ width: "20%" }}>
                        <strong>{resource[0]}</strong>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <a href={resource[1]}>
                          <FaLink />
                        </a>
                      </td>

                      {/* Time */}
                      <td>
                        <div
                          style={{
                            width: window.innerWidth < 750 ? "10em" : "100%",
                          }}
                        >
                          {resource[2]}
                        </div>
                      </td>

                      {/* Overview */}
                      <td>
                        <div
                          style={{
                            width: window.innerWidth < 750 ? "7em" : "70%",
                          }}
                        >
                          {this.splitResource(resource[3])}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            width: window.innerWidth < 750 ? "7em" : "100%",
                          }}
                        >
                          {this.splitResource(resource[4])}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </div>
      );
    } else return null;
  }
}
export default GSLinksTable;
