import React from "react";
import "./App.css";
import Footer from "./pages/home/home-components/footer";
import IntegratedMenu from "./components/web-menu/integrated-menu";
function App() {
  return (
    <div className="App">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>
      <style>
        @import
        url('https://fonts.googleapis.com/css?family=Lato|Nunito&display=swap');
      </style>
      <link
        href="https://fonts.googleapis.com/css?family=Josefin+Sans|Zilla+Slab&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.11.2/css/all.css"
      />
      <link
        rel="stylesheet"
        href="node_modules/mdbootstrap/css/bootstrap.min.css"
      />
      <link rel="stylesheet" href="node_modules/mdbootstrap/css/mdb.min.css" />
      <link
        rel="stylesheet"
        href="node_modules/mdbootstrap/css/style.css"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap"
        rel="stylesheet"
      ></link>
      <IntegratedMenu></IntegratedMenu>
      <Footer />
    </div>
  );
}

export default App;
