import styled from "styled-components";

const SectionHeader = styled.div`
  height: fit-content;
  width: 100%;
  z-index: 1;
  background-color: red;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.fontColor || "white"};
  word-wrap: break-word;
  text-align: center;
  position: relative;
  font-weight: bold;
  padding: 0.5em 0 0 0em;
`;

export default SectionHeader;
