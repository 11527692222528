import React from "react";

import "./program-overview.css";

// import header for home page
// import FirstGenHeader from "./headers/first-gen";
import VideoHeader from "./headers/gen-with-video/gen-video-format";
import Announcements from "./headers/announcements-carousel/announce-carousel";

// import data and component for service-descriptions
import { descriptions } from "./service-descriptions";

// import developed components
import SectionIconContainer from "../../components/icon-container/section-icon-container";
import SectionHeader from "../../components/program-overview/section-header";
import SectionDescription from "../../components/program-overview/section-description";
import TabsSection from "./po-tabs/tabs";
import ImageViewer from "../../components/program-overview/image-viewer-container";
// import HomePadlets from "./padlets/padlets.jsx";

class ProgramOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: 0,
      sections: descriptions,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  updateSection(e, index) {
    e.preventDefault();
    console.log("Updating Section ID", index);
    this.setState({
      currentId: index,
    });
  }

  render() {
    return (
      <div className="opening-div">
        {/* <FirstGenHeader /> */}
        <Announcements />

        <div className="page-section-layout">
          <div className="page-section-menu">
            {this.state.sections.map((item, index) => {
              return (
                <div
                  className="page-section-menu-item"
                  onClick={(e) => this.updateSection(e, index)}
                >
                  {item.sectionTitle}
                </div>
              );
            })}
          </div>
          <div className="page-section-display">
            {" "}
            <SectionHeader id={this.state.sections[this.state.currentId].id}>
              {" "}
              <h2 style={{ fontWeight: "bold" }}>
                {this.state.sections[this.state.currentId].sectionTitle}
              </h2>
            </SectionHeader>
            <SectionDescription>
              <div
                id={this.state.sections[this.state.currentId].sectionId}
                key={this.state.sections[this.state.currentId].sectionTitle}
              >
                <div className="section-overview-grid">
                  {/* <div className="section-overview-tabs">tabs</div> */}
                  <TabsSection
                    tabinfo={this.state.sections[this.state.currentId]}
                  />
                  {/*  CONTENT FOR EACH TAB */}
                  <div className="section-overview-tabcontent">
                    {this.state.sections[this.state.currentId].descriptions.map(
                      (item, index) => {
                        return (
                          <div
                            id={item.tid}
                            className={
                              this.state.sections[this.state.currentId]
                                .sectionTabContentClassName
                            }
                            key={item.id}
                          >
                            <div className="tab-container ">
                              <div className="tab-section">
                                <div className="tab-sub-heading">
                                  <h4>{item.title}</h4>
                                  <hr />
                                </div>
                                <div>{item.text}</div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>

                  <div className="section-overview-carousel">
                    <ImageViewer
                      index={this.state.sections[this.state.currentId].index}
                      carouseltype="program-overview"
                    />
                  </div>
                </div>
              </div>
            </SectionDescription>
          </div>
        </div>
        <SectionIconContainer />

        {/* {descriptions.map((item, index) => {
          // console.log(item);
          return (
            <div id={item.sectionId} key={item.sectionTitle}>
              <div>
                <AcademicServices item={item} index={index}></AcademicServices>
              </div>
              <SectionIconContainer />
            </div>
          );
        })} */}
      </div>
    );
  }
}
export default ProgramOverview;
