import styled from "styled-components";

const AnnouncementHeader = styled.div`
  /* This renders the buttons above... Edit me! */
  height: 75vh;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  overflow-y: auto;

  z-index: 0;
  @media screen and (max-width: 1024px) {
    background-size: fit;
    background-position: center;
  }
`;

export default AnnouncementHeader;
