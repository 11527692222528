import React from "react";
import "../home-styles/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";

class Footer extends React.Component {
  render() {
    return (
      <Container fluid id="footer-body">
        <Container>
          <Row id="footer-row-titles">
            <Col md={4}>
              <h1 className="footer-titles">Located At</h1>
              <ul className="footer-lists" id="footer-address">
                <li>
                  <a
                    className="text-muted footer-links"
                    href="https://map.concept3d.com/?id=1218#!m/346642?ce/34404?ct/31194"
                    style={{ color: "#0d1b2a" }}
                  >
                    Pacific Coast Campus
                    <br />
                  </a>
                </li>
                <li>
                  1305 E. Pacific Coast Highway <br /> Long Beach, CA 90806
                </li>
                <li>Located in: Building AA-109</li>
                <li className="footer-lists-contact-info">
                  Phone: <span className="text-muted">(562) 938-3177</span>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h1 className="footer-titles">Quick Links</h1>
              <ul className="footer-lists" id="footer-quick-links">
                <li>
                  <a className="text-muted footer-links" href="/about">
                    about
                  </a>
                </li>
                <li>
                  <a className="text-muted footer-links" href="/services">
                    services
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={5}>
              <Row>
                {/* <Col md={12}>
                  <h1 className="footer-titles">Stay Updated</h1>
                  <Form>
                    <Form.Row>
                      <Col>
                        <Form.Control
                          id="footer-form"
                          placeholder="Enter email"
                        />
                      </Col>
                      <Col>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Form.Row>
                  </Form>
                </Col> */}
                <Col md={5}>
                  <h1 className="footer-titles">Follow Us</h1>

                  <div
                    className="footer-icons"
                    style={{ display: "flex", paddingTop: "1em" }}
                  >
                    <a href="https://www.instagram.com/lbcc_upward_bound/">
                      <i
                        className="fab fa-instagram fa-2x"
                        style={{ color: "black" }}
                      ></i>{" "}
                    </a>
                    <a href="https://www.youtube.com/channel/UCLpp7-gaExdU1TubrkTi8yQ">
                      <i
                        className="fab fa-youtube fa-2x"
                        style={{ color: "black" }}
                      />
                    </a>
                    <a href="https://www.facebook.com/LBCCUpwardBound/">
                      <i
                        className="fab fa-facebook-f fa-2x"
                        style={{ color: "black" }}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
export default Footer;
