import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class FamilyIncome extends React.Component {
  render() {
    return (
      <Form.Group as={Row} style={{ justifyContent: "center" }}>
        <Form.Label as="legend">
          <strong>Please check the box containing your family income </strong>
        </Form.Label>
        <Col></Col>
        <Col xs={10} sm={10}>
          <Form.Group as={Row}>
            <Col sm={10}>
              <Form.Check
                type="radio"
                label="20,000-39,9999"
                name="familyIncome"
                id="income1"
                onChange={this.props.handleChange}
                value="20,000-39,9999"
              />
              <Form.Check
                type="radio"
                label="40,000-59,999"
                name="familyIncome"
                id="income2"
                onChange={this.props.handleChange}
                value="40,000-59,999"
              />
              <Form.Check
                type="radio"
                label="60,000-79,999"
                name="familyIncome"
                id="income3"
                onChange={this.props.handleChange}
                value="60,000-79,999"
                
              />
              <Form.Check
                type="radio"
                label="80,000-99,999"
                name="familyIncome"
                id="income4"
                onChange={this.props.handleChange}
                value="80,000-99,999"
              />
              <Form.Check
                type="radio"
                label="100,000+"
                name="familyIncome"
                id="income5"
                onChange={this.props.handleChange}
                value="100,00+"
              />
            </Col>
          </Form.Group>
        </Col>
        <Col></Col>
      </Form.Group>
    );
  }
}
export default FamilyIncome;
