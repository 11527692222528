import React from "react";
import { Accordion, Card, Container } from "react-bootstrap";
import "./about.css";

class Faqs extends React.Component {
  render() {
    return (
      <Container>
        <div
          className="about-section-container"
          style={{ fontSize: "18px", cursor: "pointer" }}
        >
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>
                  Do you need to be a high achieving student to be involved in
                  Upward Bound?
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    {" "}
                    Absolutely not. However, you do need to demonstrate a
                    sincere interest in completing a high school diploma and
                    attending college. This means you must have a GPA between
                    2.00-4.00 and are actively trying to improve.{" "}
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>How much does it cost to participate in Upward Bound?</div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    All program services are provided cost-free. The LBCC Upward
                    Bound program is federally funded by a grant from the US
                    Department of Education.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>
                  What are the target schools for the LBCC Upward Bound Program?
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Cabrillo High School, LB Polytechnic High School, and Wilson
                    High School.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>
                  Does participation in Upward Bound improve my child's chances
                  of getting into college?
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Over 90% of Upward Bound students are admitted to college
                    upon their high school graduation.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>Where do I pick up an application?</div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    You may print out an online application or visit the Career
                    Center at your high school and request an application from
                    your guidance counselor. The Upward Bound Program Specialist
                    will visit target schools once a week and gladly help you
                    complete it.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>
                  Why are parents required to submit financial information?
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    The LBCC Upward Bound program requires proof of
                    eligibility-income, which is why these documents are
                    requested. Financial information includes tax statements,
                    TANF, food stamps information, unemployment information,
                    Social Security, and other relevant information. Personal
                    information is not forwarded or shared with others. All
                    information is completely confidential.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div>
                  Does Upward Bound help students with other matters aside from
                  college?
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Absolutely, the Upward Bound staff is available to provide
                    academic, career and personal advisement. The staff will
                    also make referrals for both students and parents.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Container>
    );
  }
}
export default Faqs;
