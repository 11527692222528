import React from "react";

import { FaFileImage, FaLink } from "react-icons/fa";

class LinkItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      event: "",
      deconStartDateTime: "",
      deconEndDateTime: "",
    };
    this.handleOnClick = this.handleOnClick.bind(this);
    this.getImageURL = this.getImageURL.bind(this);
    this.getTime = this.getTime.bind(this);
    this.deconstructDateTime = this.deconstructDateTime.bind(this);
    this.getRecurringDays = this.getRecurringDays.bind(this);
  }

  render() {
    return (
      <tr>
        {/* Title with Link */}
        <td style={{ width: "20%" }}>
          <strong>{this.state.event.summary}</strong>
        </td>
        <td style={{ justifyContent: "center" }}>
          <a href={this.state.event.location}>
            <FaLink />
          </a>
        </td>

        {/* Description */}
        <td>
          <div
            style={{
              width: window.innerWidth < 750 ? "10em" : "100%",
            }}
          >
            {this.state.event.description}
          </div>
        </td>

        {/* Days */}
        <td style={{ width: "20%" }}>
          <div>{this.getDate()} </div>
        </td>

        {/* Time */}
        <td style={{ width: "10%" }}>{this.getTime()}</td>

        {/* Flyer Url */}
        <td style={{ width: "10%" }}> {this.getFlyer()}</td>
      </tr>
    );
  }

  getFlyer() {
    if (this.getImageURL()) {
      return (
        <a href={this.getImageURL()}>
          <FaFileImage size={20} style={{ color: "red" }}></FaFileImage>
        </a>
      );
    }
  }
  dayTextVal() {
    if (this.state.event.recurrence === null) {
      return "Date: ";
    }
    return "Days: ";
  }
  handleOnClick() {
    this.setState({ isToggleOn: false });
  }
  componentDidMount() {
    this.setState({ event: this.props.event });

    this.deconstructDateTime("start", this.props.event.start);
    this.deconstructDateTime("end", this.props.event.end);
  }
  getImageURL() {
    if (this.state.event.attachments != null) {
      // console.log("Attachements", this.state.event.attachments[0].fileId);
      let url =
        "https://drive.google.com/uc?id=" +
        this.state.event.attachments[0].fileId;
      // console.log("URL : ", url);
      return url;
    }
  }

  deconstructDateTime(type, value) {
    try {
      // console.log("DECONSTRUCTING TIME: ", type, value);
      let date = new Date(value);
      let day = date.getDate();
      let hour = date.getHours();
      let month = date.getMonth() + 1;
      let minutes = date.getMinutes();
      let weekday = date.getDay();
      let year = date.getFullYear();

      if (type === "start") {
        this.setState({
          deconStartDateTime: {
            day,
            hour,
            month,
            minutes,
            weekday,
            year,
          },
        });
      }
      if (type === "end") {
        this.setState({
          deconEndDateTime: {
            day,
            hour,
            month,
            minutes,
            weekday,
            year,
          },
        });
      }

      if (type === "recurring") {
      }
    } catch (e) {
      console.log(e);
      this.setState({
        deconStartDateTime: "TBA",
        deconEndDateTime: "",
      });
    }
  }

  getTime() {
    try {
      let timeString =
        this.convertHour(this.state.deconStartDateTime.hour) +
        this.convertMinute(this.state.deconStartDateTime.minutes) +
        "-" +
        this.convertHour(this.state.deconEndDateTime.hour) +
        this.convertMinute(this.state.deconEndDateTime.minutes);
      // let convertedTime = "";
      // convertedTime += this.convertHour(this.state.event.hour);
      // convertedTime += this.convertMinute(this.state.event.minutes);
      return timeString;
    } catch (e) {
      return "TBA";
    }
  }
  convertHour(hour) {
    let hourString = "";
    if (hour > 12) {
      hourString = +String(hour % 12);
      return hourString;
    } else return String(hour);
  }
  convertMinute(minutes) {
    let minuteString = ":";
    switch (minutes) {
      case 0:
        minuteString += "00";
        return minuteString;
      case 1:
        minuteString += "01";
        return minuteString;
      case 2:
        minuteString += "02";
        return minuteString;
      case 3:
        minuteString += "03";
        return minuteString;
      case 4:
        minuteString += "04";
        return minuteString;
      case 5:
        minuteString += "05";
        return minuteString;
      case 6:
        minuteString += "06";
        return minuteString;
      case 7:
        minuteString += "07";
        return minuteString;
      case 8:
        minuteString += "08";
        return minuteString;
      case 9:
        minuteString += "09";
        return minuteString;
      default:
        minuteString += minutes;
        return minuteString;
    }
  }

  getDate() {
    if (this.state.event.recurrence === null) {
      let dateString =
        this.state.deconStartDateTime.month +
        "/" +
        this.state.deconStartDateTime.day +
        "/" +
        this.state.deconStartDateTime.year;
      return dateString;
    } else {
      if (this.state.event.recurrence !== undefined) {
        let days = this.state.event.recurrence;

        return this.getRecurringDays(days[0]);
      }
    }
  }

  getRecurringDays(recurrenceRule) {
    if (this.recurrenceRule !== null) {
      let dayRecurrence = new RegExp("BYDAY", "g");
      let dayArray = dayRecurrence.exec(recurrenceRule);
      // console.log("Day Recurrence Array: ", dayArray);
      // console.log(typeof recurrenceRule);

      let formattedDays = [];
      let days = recurrenceRule
        .substring(dayArray.index + 6, recurrenceRule.length)
        .split(",");
      //   console.log(days);
      for (let i = 0; i < days.length; i++) {
        if (days[i] === "MO") {
          formattedDays.push("Mon \n");
        }
        if (days[i] === "TU") {
          formattedDays.push("Tues \n");
        }
        if (days[i] === "WE") {
          formattedDays.push("Wed \n");
        }
        if (days[i] === "TH") {
          formattedDays.push("Thurs \n");
        }
        if (days[i] === "FR") {
          formattedDays.push("Fri \n");
        }
        if (days[i] === "SA") {
          formattedDays.push("Sat \n");
        }
        if (days[i] === "SU") {
          formattedDays.push("Sun \n");
        }
      }
      return formattedDays;
    }
  }
}

export default LinkItem;
