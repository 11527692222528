export const descriptions = [
  {
    sectionTitle: "Academic Services",
    sectionId: "academic-overview",
    index: 0,
    sectionTabContentClassName: "academic-tabs",
    descriptions: [
      {
        title: "Tutoring",
        tid: "tutoring-tab",
        button: "tutoring-button",
        text:
          "Our virtual tutoring services focus on equipping students with successful learning strategies and essential skills that result in improved comprehension and test taking. As we provide remote services, we have extended our tutorials to include a virtual space where students can come to meet and have mature and thoughtful conversations with our outstanding instructional team including our bridge leaders who have transitioned to full-time college students.",
      },
      {
        title: "Academic Counseling",
        tid: "academic-tab",
        button: "academic-button",
        text:
          "We believe our students are capable of thriving in challenging environments. We encourage most of our students to enroll in AP and honors classes that prepare them to become successful college students. Our program specialist meets with students twice per month during a regularly scheduled appointment (Tuesday, Thursday or Saturday).",
      },
      {
        title: "SEL Workshops",
        tid: "sel-tab",
        button: "sel-button",
        text:
          "Our Socio-Emotional Workshops address the multifaceted aspects of the students socio-emotional well-being, individual growth, strengthening of interpersonal relationships and developing essential healthy coping skills. Students meet on a weekly basis to participate in an Ubuntu Circle, Mental Health Workshop and/or Mindfulness sessions to discuss topics that lead them to a state of overall wellness and feeling connected with peers and team members they value and trust.",
      },
      {
        title: "Saturday Academy",
        tid: "sat-tab",
        button: "sat-button",
        text:
          "The focus of our bi-monthly Saturday sessions are rooted in career and college exploration. Students will participate in various learning and career assessments that help to illuminate their knowledge base of various career paths and develop a deep understanding of their strengths and talents. Individual advisement, tutoring and instruction is available by appointment (9 am - 12 n) prior to the workshops (1 pm - 3 pm).",
      },
    ],
  },
  {
    sectionTitle: "Summer Programming",
    sectionId: "summer-overview",
    index: 1,
    sectionTabContentClassName: "summer-tabs",
    descriptions: [
      {
        title: "Comprehensive Academic Support Services",
        tid: "cass-tab",
        button: "cass-button",
        text:
          "During the first half of the six-week summer program, students participate in classes that will enhance and strengthen their academic skills in English, mathematics, foreign languages and science. They also attend classes and workshops in career planning, computer applications, leadership, and financial literacy. We also provide innovative STEM opportunities.",
      },
      {
        title: "Residential Component",
        tid: "residential-tab",
        button: "residential-button",
        text:
          "To recreate the college going experience, students have the opportunity to live at Whittier College for one or two weeks depending on the funding available. We prepare our students to excel in any college environment by building the skills needed during these essential six-weeks of intensive summer programming. The first four weeks are held at Long Beach City College and if students have solid attendance and demonstrate a willingness to learn and participate, they will be invited to reside at Whittier College for the last two weeks of programming.",
      },
      {
        title: "College Visits",
        tid: "college-visits-tab",
        button: "college-visits-button",
        text:
          "Historically, the LBCC Upward Bound Program has visited colleges in Northern California, including but not limited to: UC Berkeley, UC Santa Cruz, San Jose State University, University of San Francisco, Stanford University. We believe that choosing a college that is a good fit for our students requires in person visits that allow them to see and experience the campus culture. After four years being enrolled in Upward Bound, many of our students have the privilege of visiting more than twenty college campuses!",
      },
      {
        title: "Individual Counseling",
        tid: "individual-counseling-tab",
        button: "individual-counseling-button",
        text:
          "Our program specialist meets with students during the academic year and summer component to ensure students are adjusting to program expectations and demonstrating a growth mindset. Students are encouraged to step outside of their comfort zone and to trust in the process. Our goal is to support students in reaching their highest academic, social and motivational potential!",
      },
    ],
  },

  {
    sectionTitle: "STEM Programming",
    sectionId: "stem-overview",
    index: 2,
    sectionTabContentClassName: "stem-tabs",
    descriptions: [
      {
        title: "STEM Opportunities",
        tid: "stem-tab",
        button: "stem-button",
        text:
          "Exciting STEM opportunities, including but not limited to coding, robotics and solar car development, are available to students who are interested in exploring a future career in the area of science, technology engineering and/or math. During the summer of 2019, ten students had the opportunity to participate in a one-week STEM boot campus at Harvard University.",
      },
    ],
  },
  {
    sectionTitle: "Alumni",
    sectionId: "alumni-overview",
    index: 3,
    sectionTabContentClassName: "alumni-tabs",
    descriptions: [
      {
        title: "Annual Alumni Panel",
        tid: "alumni-panel-tab",
        button: "alumni-panel-button",
        text:
          "Held in January of each year, we invite outstanding alumni who are successful in their chosen career path. We strive to connect students with alumni who have succeeded in overcoming similar barriers faced by our students and who have managed to persevere and find college and life success.The continued connection with our alumni provides powerful examples to our students as well as creates opportunities for mentorship, internships and other personal and professional connection that lead our students to new and greater heights!",
      },
      {
        title: "Annual Career Fair",
        tid: "career-fair-tab",
        button: "career-fair-button",
        text:
          "Held in March of each year, alumni as well as members of the community are invited to participate in a career fair that allows our students to learn from professionals in diverse careers about their journey from high school to success in the workplace after college!",
      },
      {
        title: "Alumni Mentorship",
        tid: "alumni-mentor-tab",
        button: "alumni-mentor-button",
        text:
          "Upon high school graduation, our students are invited to return as bridge leaders the summer before their first year of college. They serve as mentors and shining examples of successful students who make consistent good choices. Many of our residential staff and tutorial team are former students who are committed to paying it forward and giving back to a program that invested so much in their development!",
      },
    ],
  },
];
