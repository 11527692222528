import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class Grade extends React.Component {
  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ justifyContent: "center" }}>
          <Form.Label as="legend">
            {" "}
            <strong>What is your current GPA?</strong>{" "}
          </Form.Label>
          <Col sm={3}>
            <Form.Control
              type="text"
              label="gpa"
              name="gpa"
              id="yes"
              value={this.props.gpa}
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default Grade;
