//react components
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./events.css";
//styled components for theme
import SectionHeader from "../../components/program-overview/section-header";
import SectionDescription from "../../components/program-overview/section-description";

// developed components
import Header from "../../components/styled-components/header.styled";
import EventsImage from "../../assets/events/ub-rocks-2.png";

import LinksTable from "../../pages/LinksTable";
import Surveys from "./survey/survey";
import GSResources from "../Resources/Resources";
import EventsListHandler from "./events-list-handler";
import GSLinksTable from "../gs-links-table";

class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentId: 0,
      sections: [
        {
          title: "Services & Events",
          id: "links",
          index: 0,
          component: (
            <div>
              <GSLinksTable />
            </div>
          ),
        },
        {
          title: "Calendar View: Upcoming Events",
          id: "upcoming-events",
          index: 1,
          component: (
            <div style={{ height: "75vh" }}>
              <iframe
                src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FLos_Angeles&amp;src=dXB3YXJkYm91bmRsYmNjQGdtYWlsLmNvbQ&amp;color=%23D50000&amp;mode=WEEK&amp;showNav=0&amp;showTabs=0&amp;showCalendars=0"
                width="100%"
                height="750px"
                frameborder="0"
              ></iframe>
            </div>
          ),
        },
        {
          title: "Surveys",
          id: "surveys",
          index: 2,
          component: (
            <div>
              <Surveys />
            </div>
          ),
        },
        {
          title: "Resources",
          id: "resources",
          index: 3,
          component: (
            <div>
              <GSResources />
            </div>
          ),
        },
      ],
    };
  }

  updateSection(e, index) {
    e.preventDefault();
    console.log("Updating Section ID", index);
    this.setState({
      currentId: index,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div id="services-main" className="opening-div">
        <Header img={EventsImage}>
          <Container>
            <Row style={{ height: "37.5vh" }}>
              <Col></Col>
              <Col
                lg={8}
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Col>
              <Col></Col>
            </Row>
            <Row style={{ height: "10vh" }}>
              <Col></Col>
              <Col>
                <div className="header-ub">Services</div>
              </Col>
              <Col></Col>
            </Row>
            <Row style={{ height: "30vh" }} />
          </Container>
        </Header>

        <div className="page-section-layout">
          <div className="page-section-menu">
            {this.state.sections.map((item, index) => {
              return (
                <div
                  className="page-section-menu-item"
                  onClick={(e) => this.updateSection(e, index)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>

          <div className="page-section-display">
            <SectionHeader id={this.state.sections[this.state.currentId].id}>
              {" "}
              <h2 style={{ fontWeight: "bold" }}>
                {this.state.sections[this.state.currentId].title}
              </h2>
            </SectionHeader>
            <SectionDescription>
              {this.state.sections[this.state.currentId].component}
            </SectionDescription>
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
