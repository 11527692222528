const allImages = [
  {
    id: "academics",
    index: 0,
    sectionImages: ["classof-2019", "bennett", "kim-class", "SGA"],
  },
  {
    id: "summer",
    index: 1,
    sectionImages: [
      "whittier-18",
      "whittier-rda-19",
      "week1grouppic",
      "summer-19-group",
      "berkeley-group-19",
      "brian-podcast",
      "google-19",
      "intel-19",
      "melly-bday",
      "mit-19",
      "norcal-group-pier39",
      "santamonica-group",
      "summer-17-hiking-group",
      "summer-18-end-celeb",
    ],
  },

  {
    id: "college",
    index: 2,
    sectionImages: [
      "UCILibrary2019",
      "berkley-lib-19",
      "career-fair-2019",
      "csulb-coe",
      "firefighter-group",
      "GradsatLibrary",
      "liza-amanda",
      "anne-frank-quote",
      "melissa-ucsb-starfish",
      "pomona-college-group",
      "UCSBVisit-Dolly",
      "ryan-harvard-coatlabs",
    ],
  },
  {
    id: "alumni",
    index: 3,
    sectionImages: ["alumni-trio-works", "ashley", "twins", "jaime&ivan"],
  },
];

export default allImages;
