import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class SchoolFilter extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Form.Group as={Row}>
          <Form.Label as="legend">
            <strong>What highschool do you currently attend?{" "}</strong>
          </Form.Label>
          <Col sm={12} >
            <Form.Check
              type="radio"
              label="Cabrillo"
              name="school"
              id="Cabrillo"
              value="Cabrillo"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="Poly"
              name="school"
              id="Poly"
              value="Poly"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="Wilson"
              name="school"
              id="Wilson"
              value="Wilson"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="None of the above"
              name="school"
              id="None of the above"
              value="None of the above"
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </React.Fragment>
    );
  }
}
export default SchoolFilter;
