// import ELT Images
import marcos from "../../../assets/students/Marcos-photo.JPG";
import jessica from "../../../assets/students/Jessicas-photo.JPG";
import leslie from "../../../assets/students/Leslies-photo-1.JPG";
import ryan from "../../../assets/students/Ryans-photo.JPG";

export const elts = [
  {
    name: "Ryan Chhoeuth",
    id: "elt-ryan",
    index: 3,
    image: {
      portrait: ryan,
      alt: "marcos-portrait",
    },
    intro:
      "Ryan (far-right) is a graduate of Wilson High Shool and is attending Long Beach City College. Ryan is hard-working and dedicated with everything. Nothing is not impossible. He lives by the motto: Where there's a will there's a way. Ryan is very knowledgeable and likes to engage people of all backgrounds into interesting conversation and likes to receive everyone's input.",
    funfact: " Fun fact: Ryan will be running for president in 2040",
    tags: ["#2040", "#WhereTheresAWillTheresAWay"],
  },
  {
    name: "Jessica Cruz",
    id: "elt-jessica",
    index: 0,
    image: {
      portrait: jessica,
      alt: "jessica-portrait",
    },
    intro:
      "Jessica (left-center) is a graduate of Wilson High Shool and is attending Long Beach City College. Jessica is a brilliant resilient student who has a passion for many things including: history,music [composing, listening, playing violin, you name it],people, and food. She likes to spend her time in activities that allow for creativity ranging making crafts, baking, organizing, and playing video games among other activities. ",
    funfact: "Fun fact: Jessica is a talented violinist!",
    tags: ["#JessicaNeverMissesABeat"],
  },
  {
    name: "Marcos Ramirez",
    id: "elt-marcos",
    index: 2,
    image: {
      portrait: marcos,
      alt: "marcos-portrait",
    },
    intro:
      "Marcos (far-left) is a graduate of Wilson High School and is attending Long Beach City College. While at LBCC Marcos discovered his passion for computer science and is currently majoring in Computer Science. Apart from studying hard, Marcos enjoys playing video games, spending time with his family, cracking jokes and attending to his dogs.  ",
    funfact:
      "Fun Fact: Marcos is a doglover. Converse with him about dogs and you are speaking his language.",
    tags: ["#FutureEngineer", "#LivingADogDadLife"],
  },
  {
    name: "Leslie Vargas",
    id: "elt-leslie",
    index: 1,
    image: {
      portrait: leslie,
      alt: "leslie-portrait",
    },
    intro:
      "Leslie (right-center) is a graduate of Wilson High Shool and is attending Long Beach City College. Leslie is one of the most compassionate, reliable, and caring person you will ever meet. These attributes have driven her desire to become a primary school teacher. Leslie is a great conversation starter & will always have something to share with you. ",
    funfact:
      "Fun fact: Leslie can saanng. Catch her singing holiday tunes at your local neighborhood during the Season. ",
    tags: ["#LeslieIsSomeoneYouCanLeanOn"],
  },
];
