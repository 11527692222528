import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index - 1,
      name: "",
      value: this.props.selected,
    };
    this.updateParent = this.updateParent.bind(this);
  }

  updateParent(event) {
    const { handleChange } = this.props;
    handleChange(event);
    this.setState({
      value: "",
    });
  }

  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ justifyContent: "center" }}>
          <Form.Label as="legend">
            <strong>
              Please choose the word that you feel describes you best
            </strong>
          </Form.Label>
          <Col></Col>
          <Col xs={10} sm={10}>
            <Form.Group as={Row}>
              <Col sm={10}>
                <Form.Check
                  type="radio"
                  label={this.props.items.a}
                  name="selected"
                  id={this.props.items.a}
                  onChange={this.updateParent}
                  value="a"
                />
                <Form.Check
                  type="radio"
                  label={this.props.items.b}
                  name="selected"
                  id={this.props.items.b}
                  onChange={this.updateParent}
                  value="b"
                />
                <Form.Check
                  type="radio"
                  label={this.props.items.c}
                  name="selected"
                  id={this.props.items.c}
                  onChange={this.updateParent}
                  value="c"
                />
                <Form.Check
                  type="radio"
                  label={this.props.items.d}
                  name="selected"
                  id={this.props.items.d}
                  onChange={this.updateParent}
                  value="d"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col></Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default Question;
