import React from "react";
import "./padlet-next-gen.css";

class PadletNextGen extends React.Component {
  render() {
    return (
      <div className="padlet-embed padlet-embed-container">
        <p style={{ padding: "0", margin: "0" }}>
          <iframe
            title="next-gen-padlet"
            src="https://padlet.com/embed/squcrgtqo0bhogjw"
            frameborder="0"
            allow="camera;microphone;geolocation"
            className="padlet-frame"
          ></iframe>
        </p>
        <div style={{ padding: "8px", textAlign: "right", margin: "0" }}>
          <a
            href="https://padlet.com?ref=embed"
            style={{
              padding: "0",
              margin: "0",
              border: "none",
              display: "block",
              lineHeight: "1",
              height: "16px",
            }}
          >
            <img
              src="https://padlet.net/embeds/made_with_padlet.png"
              width="86"
              height="16"
              style={{
                padding: "0",
                margin: "0",
                background: "none",
                border: "none",
                display: "inline-block",
                boxShadow: "none",
              }}
              alt="Made with Padlet"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default PadletNextGen;
