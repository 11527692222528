import React from "react";
import { bool, func } from "prop-types";
import { StyledHamburger } from "./hamburger-menu.styled.jsx";

const Hamburger = ({ open, setOpen }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{returnMenu()}</div>
      <StyledHamburger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledHamburger>
    </div>
  );
};

const returnMenu = () => {
  if (window.innerWidth > 1200) {
    return (
      <div style={{ padding: "0em 1em 0em 0em", color: "white" }}> Menu </div>
    );
  } else {
    return <div></div>;
  }
};
Hamburger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Hamburger;
