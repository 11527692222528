import React from "react";

// import AboutForms from "./about-forms.jsx";
import ProgramSummary from "./ub-prog-desc.jsx";
import ServicesSummary from "./ub-serv-desc.jsx";
import Staff from "./staff.jsx";
import Faqs from "./faqs.jsx";
import Trio from "./about-trio.jsx";

//importing styled components
import ImageHeader from "../../components/styled-components/header.styled";
import SectionHeader from "../../components/program-overview/section-header";
import SectionDescription from "../../components/program-overview/section-description";
// import HeaderIconContainer from "../../components/icon-container/header-icon-container";
import sequoia from "../../assets/about/sequoias-2.jpg";

import { Row, Container, Col } from "react-bootstrap";

import "./about.css";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: 0,
      sections: [
        {
          title: "TRIO Upward Bound",
          id: "trio-description",
          index: 0,
          component: (
            <div>
              <Trio /> <ProgramSummary />
            </div>
          ),
        },
        {
          title: "Eligibility",
          id: "eligibility",
          index: 1,
          component: (
            <div>
              <ServicesSummary />
            </div>
          ),
        },
        {
          title: "Frequently Asked Questions",
          id: "faqs",
          index: 2,
          component: (
            <div>
              <Faqs />
            </div>
          ),
        },
        {
          title: "Contact Us",
          id: "contact-us",
          index: 3,
          component: (
            <div>
              <Staff />
            </div>
          ),
        },
      ],
    };
    // this.updateSection = this.updateSection.bind(this);
  }
  componenDidMount() {
    window.scrollTo(0, 0);
    this.updateSection(0);
  }

  updateSection(e, index) {
    e.preventDefault();
    console.log("Updating Section ID", index);
    this.setState({
      currentId: index,
    });
  }
  render() {
    return (
      <div className="opening-div">
        <ImageHeader img={sequoia}>
          <Container>
            <Row style={{ height: "37.5vh" }} />

            <Row style={{ height: "10vh" }}>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <Row style={{ color: "white" }}>
                  <h3>i hear you</h3>
                </Row>
              </Col>
              <Col>
                <div className="header-ub">Ubuntu</div>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <Row
                  style={{
                    color: "white",
                  }}
                >
                  <h3>i see you</h3>
                </Row>{" "}
              </Col>
            </Row>
            <Row style={{ height: "30vh" }} />
          </Container>
        </ImageHeader>

        <div className="page-section-layout-question">
          <div className="page-section-menu">
            {this.state.sections.map((item, index) => {
              return (
                <div
                  className="page-section-menu-item"
                  onClick={(e) => this.updateSection(e, index)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className="page-section-display">
            <SectionHeader id={this.state.sections[this.state.currentId].id}>
              {" "}
              <h2 style={{ fontWeight: "bold" }}>
                {this.state.sections[this.state.currentId].title}
              </h2>
            </SectionHeader>

            <SectionDescription>
              {this.state.sections[this.state.currentId].component}
            </SectionDescription>
          </div>
          <div className="page-section-question">
            {" "}
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdm1iAZdSqJpzqC-Noy7DzARQXAsBHq81UoVOnjYMixr6gNqQ/viewform?embedded=true"
              className="question-iframe"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            ></iframe>{" "}
          </div>
        </div>
      </div>
    );
  }
}
export default About;
