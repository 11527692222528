import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class FamilySize extends React.Component {
  render() {
    return (
      <Form.Group as={Row} style={{ justifyContent: "center" }}>
        <Form.Label as="legend">
          <strong>Please enter your family size </strong>
        </Form.Label>
        <Col></Col>
        <Col xs={10} sm={10}>
          <Row style={{ padding: "1em" }}>
            <Form.Control
              type="text"
              placeholder="Enter family size"
              name="familySize"
              value={this.props.familySize}
              onChange={this.props.handleChange}
            ></Form.Control>
          </Row>
        </Col>
        <Col></Col>
      </Form.Group>
    );
  }
}
export default FamilySize;
