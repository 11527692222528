import app from "firebase/app";
import "firebase/auth";
import "firebase/functions";
// Reference to set this up: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial

// Your web app's Firebase configuration
var prodFiBaConfig = {
  apiKey: process.env.REACT_APP_PROD_API_KEY,
  authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
  projectId: process.env.REACT_APP_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PROD_APP_ID,
  measurementId: process.env.REACT_APP_PROD_MESSAGING_MEASUREMENT_ID,
};

var devFiBaConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
  measurementId: process.env.REACT_APP_DEV_MESSAGING_MEASUREMENT_ID,
};

const fiBaConfig =
  process.env.NODE_ENV === "production" ? prodFiBaConfig : devFiBaConfig;
class Firebase {
  constructor() {
    // Initialize Firebase
    app.initializeApp(fiBaConfig);
    this.auth = app.auth();
    this.functions = app.functions();
  }

  //all functions go here
  eligibility = (data) => {
    let elgibFunction = this.functions.httpsCallable("eligibility");
    elgibFunction(data);
  };
}

export default Firebase;
