import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./about.css";

import success from "../../assets/about/icons/noun-success-white.png";
import education from "../../assets/about/icons/noun-education-white.png";
import confidence from "../../assets/about/icons/noun-trust-white.png";

class ProgramDescription extends React.Component {
  render() {
    return (
      <Container>
        <div style={{ padding: "2em 0em 0em 0em" }}>
          <p className="paragraph">
            The Upward Bound Program provides comprehensive academic, social,
            and emotional support services to 67 enrolled participants. The
            program serves three target high schools in the Long Beach Unified
            School District with the overall goal of assisting participants to
            successfully enroll in and complete a post-secondary education. The
            Program accomplishes this objective by enhancing participants’
            academic skills, personal motivation and confidence needed to
            succeed in college.
          </p>
          <p className="paragraph">
            Upward Bound recruits students who are from low-income families,
            potential first generation college graduates and have a high
            academic need for services. Foster youth and students who have
            complex barriers impeding their academic success are a high priority
            in the recruitment process. Programming is structured to enhance
            participants’ academic skills, personal motivation, effective
            communication and leadership skills, creative and critical thinking
            skills, a positive attitude toward learning, and tolerance toward
            others. Participants attend the program on a year round basis and
            receive high school elective credit for completing an intensive
            six-week summer program. As of March 2020, all services have been
            replicated and students have services available six days a week.
          </p>
        </div>
        <div className="prog-icon-container">
          <Row>
            <Col>
              <img
                src={education}
                alt="acadamic-skills"
                style={{
                  height: "10vh",
                  backgroundColor: "red",
                  padding: "1em",
                }}
              />
              <div style={{ color: "black" }}>Academic Skills</div>
            </Col>
            <Col>
              <img
                src={confidence}
                alt="confidence"
                style={{
                  height: "10vh",
                  backgroundColor: "red",
                  padding: "1em",
                }}
              />
              <div style={{ color: "black" }}>Motivation & Confidence</div>
            </Col>
            <Col>
              <img
                src={success}
                alt="success"
                style={{
                  height: "10vh",
                  backgroundColor: "red",
                  padding: "1em",
                }}
              />
              <div style={{ color: "black" }}>Success</div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default ProgramDescription;
