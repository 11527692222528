export const ABOUT = "/about";

export const SERVICES = "/services";
export const GRADS = "/grads/#";
export const HOME = "/";

export const PROGRAMOVERVIEW = "/program-overview";

export const LINKSTABLE = "/services";

export const TRUECOLORS = "/true-colors-survey";

export const STUDENTS = "/students";

export const NEXTGEN = "/celebrate-next-gen";
