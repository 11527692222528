import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class Grade extends React.Component {
  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ justifyContent: "center" }}>
          <Form.Label as="legend">
            <strong>What grade are you currently in? </strong>
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="Freshman"
              name="grade"
              id="yes"
              value="Freshman"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="Sophmore"
              name="grade"
              id="10"
              value="Sophmore"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="Junior"
              name="grade"
              id="11"
              value="Junior"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="Senior"
              name="grade"
              id="12"
              value="Senior"
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default Grade;
