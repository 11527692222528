import styled from "styled-components";

const Header = styled.div`
  /* This renders the buttons above... Edit me! */
  height: 75vh;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    background-size: fit;
    background-position: center;
  }
`;

export default Header;

// const Content = styled.div`
//     background-image: url(${props => props.img});
// `;
