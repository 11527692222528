import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class StemInterest extends React.Component {
  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ justifyContent: "center" }}>
          <Form.Label as="legend">
            <strong> Are you interested in a career in STEM? </strong> *Note: Not a requirement to participate in program
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="Yes"
              name="stemInterest"
              id="yes"
              value="yes"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="No"
              name="stemInterest"
              id="no"
              value="no"
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default StemInterest;
