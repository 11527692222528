import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class CitizenFilter extends React.Component {
  render() {
    return (
      <Form.Group as={Row} style={{ width: "100%" }}>
        <Form.Label as="legend">
          <strong>Are you a U.S. Citizen or Resident?</strong>{" "}
        </Form.Label>
        <Col sm={12} lg={12}>
          <Form.Check
            type="radio"
            label="Yes"
            name="citizen"
            id="yes"
            value="yes"
            onChange={this.props.handleChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="citizen"
            id="no"
            value="no"
            onChange={this.props.handleChange}
          />
        </Col>
      </Form.Group>
    );
  }
}
export default CitizenFilter;
