import React from "react";

import "./resources.css";
// import MathResources from "./Math/math-resources.jsx";
// import EnglishResources from "./english/en-resources.jsx";
// import ScienceResources from "./science/sci-resources.jsx";
// import StayConnected from "./updates/stay-connected.jsx";
// import SupLearning from "./updates/sup-learning.jsx";
import GSResources from "./google-sheets-resources/google-sheets-resources";

// import ResourceBanner from "../../assets/resources/resource-banner/Ubuntu_Upward_Bound.jpg";

class Resources extends React.Component {
  render() {
    return (
      <div id="resources" style={{ display: "block" }}>
        <GSResources />
      </div>
    );
  }
}

export default Resources;
