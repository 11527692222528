import React from "react";
import axios from "axios";

// react bootstrap components
import { Table } from "react-bootstrap";
import { FaLink } from "react-icons/fa";

// import css styling
import "./google-sheets-resources.css";

class GSResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
    };
  }
  componentDidMount() {
    console.log(" Logging Getting Method to Google Sheet");
    let opt = {
      method: "GET",
      url: "https://us-central1-ububuntu-889ef.cloudfunctions.net/resources",
    };

    axios.get(opt.url).then((res) => {
      console.log(res.data);
      this.setState({
        resources: res.data,
      });
    });
  }
  render() {
    if (this.state.resources.length !== 0) {
      return (
        <div id="resources-table">
          <Table responsive="md" hover>
            <thead>
              <tr>
                <th>{this.state.resources[0][0]}</th>
                <th style={{ textAlign: "center" }}>
                  {this.state.resources[0][1]} [<FaLink />]
                </th>
                <th>{this.state.resources[0][2]}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.resources.map((resource, index) => {
                if (index === 0) {
                  return null;
                } else {
                  return (
                    <tr>
                      {/* Title with Link */}
                      <td style={{ width: "20%" }}>
                        <strong>{resource[0]}</strong>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <a href={resource[1]}>
                          <FaLink />
                        </a>
                      </td>

                      {/* Description */}
                      <td>
                        <div
                          style={{
                            width: window.innerWidth < 750 ? "10em" : "100%",
                          }}
                        >
                          {resource[2]}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </div>
      );
    } else return null;
  }
}

export default GSResources;
