import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { bool } from "prop-types";
import * as Routes from "../../constants/routes";

import { StyledMenu } from "./web-menu.styled.jsx";

import "./menu-styling.css";

const WebMenu = ({ open, setOpen, ...props }) => {
  return (
    <div>
      <StyledMenu open={open} onClick={() => setOpen(!open)} {...props}>
        {/* HOME */}
        <Row className="menuItemContainerCSS">
          <div style={{ display: "flex-box", justifyContent: "center" }}>
            <Link to={"/"}>
              <div>Home</div>
            </Link>
            <div className="subMenuItemContainerCSS">
              <a href="/#academic-overview" className="subLinkCSS">
                Academic
              </a>
              <a href="/#summer-overview" className="subLinkCSS">
                Summer
              </a>
              <a href="/#stem-overview" className="subLinkCSS">
                STEM
              </a>
              <a href="/#alumni-overview" className="subLinkCSS">
                Alumni
              </a>
            </div>
            <hr style={{ backgroundColor: "white" }}></hr>
          </div>
        </Row>
        {/* ABOUT */}
        <Row className="menuItemContainerCSS">
          <div style={{ display: "flex-box", justifyContent: "center" }}>
            <Link to={Routes.ABOUT}>
              <div>About</div>
            </Link>
            <div className="subMenuItemContainerCSS">
              <a
                href={Routes.ABOUT + "/#trio-description"}
                className="subLinkCSS"
              >
                Trio
              </a>
              <a href={Routes.ABOUT + "/#eligibility"} className="subLinkCSS">
                Eligbility
              </a>
              <a href={Routes.ABOUT + "/#contact-us"} className="subLinkCSS">
                Contact Us
              </a>
              <a href={Routes.ABOUT + "/#faqs"} className="subLinkCSS">
                FAQs
              </a>
            </div>
            <hr style={{ backgroundColor: "white" }}></hr>
          </div>
        </Row>
        {/* SERVICES */}
        <Row className="menuItemContainerCSS">
          <div style={{ display: "flex-box", justifyContent: "center" }}>
            <Link to={Routes.SERVICES}>
              <div>Services</div>
            </Link>
            <div className="subMenuItemContainerCSS">
              <a
                href={Routes.SERVICES + "/#upcoming-events"}
                className="subLinkCSS"
              >
                Upcoming Events
              </a>
              <a href={Routes.SERVICES + "/#links"} className="subLinkCSS">
                Services
              </a>
              <a href={Routes.SERVICES + "/#surveys"} className="subLinkCSS">
                Surveys
              </a>
              <a href={Routes.SERVICES + "/#resources"} className="subLinkCSS">
                Resources
              </a>
            </div>
            <hr style={{ backgroundColor: "white" }}></hr>
          </div>
        </Row>
        <Row className="menuItemContainerCSS">
          <div style={{ display: "flex-box", justifyContent: "center" }}>
            <Link to={Routes.STUDENTS}>
              <div>Students</div>
            </Link>
            <div className="subMenuItemContainerCSS">
              <a href={Routes.STUDENTS + "/#elt"} className="subLinkCSS">
                Exec Leadership Team
              </a>
              <a href={Routes.STUDENTS + "/#seniors"} className="subLinkCSS">
                Seniors
              </a>
              <a
                href={Routes.STUDENTS + "/#tech-groups"}
                className="subLinkCSS"
              >
                Student Technology Groups
              </a>
            </div>
            <hr style={{ backgroundColor: "white" }}></hr>
          </div>
        </Row>
      </StyledMenu>
    </div>
  );
};

WebMenu.propTypes = {
  open: bool.isRequired,
};

export default WebMenu;
