import React from "react";

//IMPORT UTILITIES
import request from "superagent";
import heapSortByTitle from "../utilities/heap-sort-eventTitle";

// IMPORT COTS
import { Table } from "react-bootstrap";
import { FaLink } from "react-icons/fa";

// IMPORT DEVELOPED COMPONENTS
import LinkItem from "./LinkItem";

class LinksTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recurringEvents: [],
    };

    this.getEvents = this.getEvents.bind(this);
    this.removeEvents = this.removeEvents.bind(this);
    this.checkRecurrence = this.checkRecurrence.bind(this);
  }

  getEvents(callback) {
    const API_KEY = "AIzaSyARUHXQehVGNrJflZ5ycwr_BphvUbhtOH4";
    const CALENDAR_ID = "upwardboundlbcc@gmail.com";
    let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`;
    request.get(url).end((err, resp) => {
      const events = [];
      if (!err) {
        let creationDate = new Date(2021, 0, 1);

        JSON.parse(resp.text).items.map((event) => {
          if (event.status !== "cancelled" && events.length < 100) {
            let eventStartDate = new Date(event.start.dateTime);
            // console.log("Event ", event);
            // console.log("Event starts on : ", eventStartDate);
            // console.log("Creation Date starts on : ", creationDate);

            // get attachments in event
            let eventAttachments = null;
            if (event.attachments !== undefined) {
              eventAttachments = [...event.attachments];
            }

            let eventDescription = null;
            if (event.description !== undefined) {
              eventDescription = event.description;
            }

            let recurrenceRule = null;
            if (event.recurrence !== undefined) {
              recurrenceRule = event.recurrence;
            }

            //make sure all events are past current date
            if (eventStartDate >= creationDate) {
              // console.log("RECENT Event: ", event);

              events.push({
                title: event.summary,
                date: event.start.dateTime,
                start: event.start.dateTime,
                end: event.end.dateTime,
                summary: event.summary,
                location: event.location,
                attachments: eventAttachments,
                description: eventDescription,
                recurrence: recurrenceRule,
              });
            }
            //will need some function to account for events that are currently occurring already occurred
          }
          return events;
        });
        // console.log("List of Events", events);
        callback(events);
      }
    });
  }

  removeEvents(arr) {
    let recurringEvents = this.checkRecurrence(arr);
    // console.log("Recurring Events", recurringEvents);

    let value = 0;
    let filterDate = new Date(); //todays Date
    // console.log("Event Filter Date ", filterDate);
    for (; value < arr.length; value++) {
      let eventDate = new Date(arr[value].start);
      // console.log("-------------------------------------");
      // console.log("Event Title: ", arr[value].title);
      // console.log("Event Start Date ", eventDate);

      if (eventDate < filterDate) {
        // console.log("Splicing Event: ", arr[value]);
        arr.splice(value, 1);
        value--;
      }
    }
    return recurringEvents;
  }

  checkRecurrence(arr) {
    let i;
    const tempArr = [];
    for (i = 0; i < arr.length; i++) {
      if (arr[i].recurrence !== null) {
        // console.log("Event", arr[i].title);
        tempArr.push(arr[i]);
        arr.splice(i, 1);
        i--;
      }
    }

    return tempArr;
  }

  componentDidMount() {
    this.getEvents((events) => {
      // console.log("Events Reordered ", events);
      const recurring = this.removeEvents(events);
      heapSortByTitle(recurring);

      this.setState({ recurringEvents: recurring });
    });
  }

  
  render() {
    return (
      <Table responsive="md" hover>
        <thead>
          <tr>
            <th>Service</th>
            <th>
              Link [<FaLink />]
            </th>
            <th>Description</th>
            <th>Days</th>
            <th>Time</th>
            <th>Flyer</th>
          </tr>
        </thead>
        <tbody>
          {this.state.recurringEvents.map((item) => {
            return <LinkItem event={item} />;
          })}
        </tbody>
      </Table>
    );
  }
}

export default LinksTable;
