import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class AcademicNeed extends React.Component {
  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ justifyContent: "center" }}>
          <Form.Label as="legend">
            <strong>Do you have any learning challenges ?</strong>
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="Yes"
              name="academicNeed"
              id="yes"
              value="yes"
              onChange={this.props.handleChange}
            />
            <Form.Check
              type="radio"
              label="No"
              name="academicNeed"
              id="no"
              value="no"
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default AcademicNeed;
