import React from "react";

import ElTeam from "./elt-info/elt-info.jsx";
import SeniorCollegePadlet from "./seniors/college-padlet";
import "./students-main.css";

class StudentsMain extends React.Component {
  render() {
    return (
      <div className="opening-div">
        <div className="student-header">
          <div className="student-header-grid">
            <div className="student-header-grid-title">
              Meet Your Peer Leadership Team
            </div>
          </div>
        </div>
        <ElTeam />
        <div className="padlet-grid-1">
          <h1 className="padlet-header">Featured Padlets</h1>
          <div className="padlet-a">
            <div className="padlet-embed padlet-wrapper">
              <iframe
                src="https://padlet.com/padlets/n5ukvcxskaw5kgsh/embeds/preview_embed"
                className="iframe-style"
                frameBorder="0"
              ></iframe>
            </div>
          </div>

          <div className="padlet-b">
            <div className="padlet-embed padlet-wrapper">
              <iframe
                src="https://padlet.com/padlets/squcrgtqo0bhogjw/embeds/preview_embed"
                className="iframe-style"
                frameBorder="0"
              ></iframe>
            </div>
          </div>

          <div className="padlet-c">
            {" "}
            <div className="padlet-embed padlet-wrapper">
              <iframe
                src="https://padlet.com/padlets/nhnetakq3r1jw40f/embeds/preview_embed"
                className="iframe-style"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
        {/* <SeniorCollegePadlet /> */}
      </div>
    );
  }
}

export default StudentsMain;
