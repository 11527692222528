import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { RiSurveyFill } from "react-icons/ri";

import axios from "axios";

import trioLogo from "../../../assets/logos/trio_logo.jpg";

import { withFirebase } from "../../../components/firebase/fb-index";
import Question from "./questions";

const initialState = {
  index: 1, // Default is Step 1
  showForm: true,
  disable: true,

  //personal information input boxes
  firstName: null,
  lastName: null,
  responses: [],
  results: false,
  selected: null,
  previousValues: [],
  a: 0,
  b: 0,
  c: 0,
  d: 0,
};

const items = [
  { a: "Receptive", b: "Practical", c: "Complex", d: "Easily Bored" },
  { a: "Genuine", b: "Responsible", c: "Intelligent", d: "Spontaneous" },
  { a: "Agreeable", b: "Established", c: "Logical", d: "Active" },
  { a: "Tender", b: "Faithful", c: "Calm", d: "Impulsive" },
  { a: "Devoted", b: "Conservative", c: "Abstract", d: "Energetic" },
  { a: "Open", b: "Reliable", c: "Curios", d: "Bold" },
  { a: "Cooperative", b: "Sensible", c: "Innovative", d: "Broad-minded" },
  { a: "Romantic", b: "Efficient", c: "Cool", d: "Skilled" },
  { a: "Friendly", b: "Trustworthy", c: "Inventive", d: "Daring" },
  { a: "Good natured", b: "Organized", c: "Ingenious", d: "Qualified" },
  { a: "Sincere", b: "Patriotic", c: "Work-is Play", d: "Adventurous" },
  { a: "Easy-going", b: "Dependable", c: "Academic", d: "Competent" },
  { a: "Sympathetic", b: "Stable", c: "Conceptual", d: "Tolerant" },
  { a: "Nurturing", b: "Loyal", c: "Proficient", d: "Competitive" },
  { a: "Creative", b: "Traditional", c: "Original", d: "Enterprising" },
];

class FormController extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = initialState;

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);

    this.handleEligibilityFormOpen = this.handleEligibilityFormOpen.bind(this);
    this.handleEligibilityFormClose = this.handleEligibilityFormClose.bind(
      this
    );
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  // Use the submitted data to set the state
  handleChange(event) {
    console.log("Event", event);
    console.log("Event Target Name", event.target.name);
    console.log("Event Target Value", event.target.value);
    console.log("Event Target Value", event.target.id);
    if (this.state.index === 1) {
      this.setState({
        disable: false,
      });
    }
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });

    //check if all fields are validated for the personal-data
  }

  checkAllFields() {
    if (this.state.studentName !== null) {
      this.setState({
        disable: false,
      });
    }
  }

  // Trigger an alert on form submission

  handleSubmit = (event) => {
    event.preventDefault();
    let selectedValue = this.state.selected;

    let count = this.state[selectedValue] + 1;
    this.state[selectedValue] = count;

    let index = this.state.index;

    if (index > 1) {
      this.state.responses.push(items[index - 2][selectedValue]);
    }

    let addToSheet = [
      this.state.firstName,
      this.state.lastName,
      this.state.a,
      this.state.b,
      this.state.c,
      this.state.d,
    ];

    this.state.responses.forEach((responseValue) => {
      addToSheet.push(responseValue);
    });

    // console.log(addToSheet);
    let opt = {
      method: "POST",
      url: "https://us-central1-ububuntu-889ef.cloudfunctions.net/color",
      data: addToSheet,
    };

    axios.post(opt.url, opt.data).then((res) => {
      // console.log(res);
    });
    this.setState({
      results: true,
      showForm: false,
    });
  };

  filterType() {
    // console.log(this.state);
    let step = this.state.index;

    if (step === 1) {
      return (
        <fieldset>
          <Form.Group as={Row} style={{ padding: "1em" }}>
            {" "}
            <Form.Label>
              <strong>Please Enter Your Name</strong>
            </Form.Label>
            <Col xs={10} sm={7}>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name "
                value={this.firsttName}
                onChange={this.handleChange}
              />{" "}
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last Name "
                value={this.lastName}
                onChange={this.handleChange}
              />
            </Col>
          </Form.Group>
        </fieldset>
      );
    } else {
      return (
        <Question
          index={step}
          handleChange={this.handleChange}
          items={items[step - 2]}
          selected={this.state.selected}
        />
      );
    }
  }

  handleEligibilityFormOpen() {
    this.setState({
      showForm: true,
    });
  }
  handleEligibilityFormClose() {
    this.setState({
      showForm: false,
      results: false,
      index: 1,
    });
  }

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let selectedValue = this.state.selected;
    let count = this.state[selectedValue] + 1;

    let index = this.state.index;
    if (index > 1) {
      this.state.responses.push(items[index - 2][selectedValue]);
      this.state.previousValues.push(selectedValue);
    }

    // If the current step is 1 or 2, then add one on "next" button click
    index = index >= 15 ? 16 : index + 1;

    this.setState({
      [this.state.selected]: count,
      index: index,
      previousValue: selectedValue,
    });
    console.log("IN NEXT BUTTON : ", this.state);
  }
  _prev() {
    let index = this.state.index;
    index = index <= 1 ? 1 : index - 1;
    console.log("In previous button");
    if (index > 1) {
      let poppedResult = this.state.previousValues.pop();
      this.state.responses.pop();
      console.log("popped result:", poppedResult);
      let count = this.state[poppedResult] - 1;
      this.setState({
        [this.state.selected]: count,
        index: index,
      });
    } else {
      // If the current step is 2 or 3, then subtract one on "previous" button click
      this.setState({
        index: 1,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
      });
    }

    console.log("IN PREVIOUS BUTTON : ", this.state);
  }

  // The "next" and "previous" button functions
  previousButton() {
    let index = this.state.index;
    // If the current step is not 1, then render the "previous" button
    if (index !== 1) {
      return (
        <Button
          onClick={this._prev}
          style={{ backgroundColor: "white", color: "red" }}
        >
          <strong>Previous</strong>
        </Button>
      );
    }
    // ...else return nothing
    return null;
  }

  nextButton() {
    let index = this.state.index;
    // If the current step is not 6, then render the "next" button
    if (index < 16) {
      return (
        <Button
          className="float-right"
          type="button"
          onClick={this._next}
          id="nextButton"
          disabled={this.state.disable}
          style={{ backgroundColor: "black", color: "white" }}
        >
          <strong> Next</strong>
        </Button>
      );
    } else {
      return (
        <Button
          className="float-right"
          type="button"
          onClick={this.handleSubmit}
          id="nextButton"
          style={{ backgroundColor: "black", color: "white" }}
        >
          <strong> Submit</strong>
        </Button>
      );
    }
  }

  // getDominantColor() {
  //   const values = {
  //     0: ["a", "blue"],
  //     1: ["b", "gold"],
  //     2: ["c", "green"],
  //     3: ["d", "orange"],
  //   };
  //   let max = 0;
  //   let color = "";
  //   for (let i = 0; i < 4; i++) {
  //     let letter = values[i][0];
  //     if (this.state[letter] > max) {
  //       max = this.state.letter;
  //       color = values[i][1];
  //     }
  //   }
  // }

  getSupportingColor() {
    return (
      <div>
        <div>Blue: {this.state.a}</div>
        <div>Gold: {this.state.b}</div>
        <div>Green: {this.state.c}</div>
        <div>Orange: {this.state.d}</div>
      </div>
    );
  }

  showResults() {
    return (
      <Row>
        <Col>
          <strong>Your results have been submitted!</strong>
        </Col>
        <Col>
          <Row>
            <Col>
              {" "}
              <strong> Your color results:</strong>{" "}
            </Col>
          </Row>
          <Row>
            <Col> {this.getSupportingColor()}</Col>
          </Row>
        </Col>
      </Row>
    );
  }
  render() {
    return (
      <>
        <div>
          <RiSurveyFill
            size={18}
            style={{ color: "white", cursor: "pointer" }}
            onClick={this.handleEligibilityFormOpen}
          ></RiSurveyFill>
        </div>
        <Modal
          size="md"
          show={this.state.showForm}
          onHide={this.handleEligibilityFormClose}
          style={{
            backgroundColor: "black",
          }}
        >
          <Modal.Header closeButton style={{ backgroundColor: "white" }}>
            <Modal.Title>
              {" "}
              <img
                src={trioLogo}
                style={{ height: "2em", width: "2em" }}
                alt="trio-logo"
              ></img>
              <strong> True Colors Activity</strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{
              height: "auto",
              justifyContent: "center",
            }}
          >
            <React.Fragment>
              <Form
                onSubmit={this.handleSubmit}
                style={{
                  textAlign: "center",
                }}
              >
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {this.filterType()}
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={6} sm={6} md={6} lg={6}>
                {this.previousButton()}
              </Col>
              <Col xs={6} sm={6} md={6} lg={6}>
                {this.nextButton()}
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          show={this.state.results}
          onHide={this.handleEligibilityFormClose}
          style={{
            backgroundColor: "black",
          }}
        >
          <Modal.Header closeButton style={{ backgroundColor: "white" }}>
            <Modal.Title>
              {" "}
              <img
                src={trioLogo}
                style={{ height: "2em", width: "2em" }}
                alt="trio-logo"
              ></img>
              <strong> True Colors Activity</strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{
              height: "auto",
              justifyContent: "center",
            }}
          >
            <React.Fragment>
              <Form
                onSubmit={this.handleSubmit}
                style={{
                  textAlign: "center",
                }}
              >
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {this.showResults()}
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withFirebase(FormController);
