import React from "react";
import { Form, Row, Col } from "react-bootstrap";

class PersonalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentName: "",
      studentEmail: "",
      studentPhone: "",
      parentName: "",
      parentEmail: "",
      parentPhone: "",
    };
    this.handleStudentName = this.handleStudentName.bind(this);
    this.handleStudentEmail = this.handleStudentName.bind(this);
    this.handleStudentPhone = this.handleStudentName.bind(this);
    this.handleParentName = this.handleStudentName.bind(this);
    this.handleParentEmail = this.handleStudentName.bind(this);
    this.handleParentPhone = this.handleStudentName.bind(this);
  }

  //   student handlers
  handleStudentName(event) {
    this.setState({
      studentName: event.target.value,
    });
  }
  handleStudentEmail(event) {
    this.setState({
      studentPhone: event.target.value,
    });
  }
  handleStudentPhone(event) {
    this.setState({
      studentPhone: event.target.value,
    });
  }

  //   parent handlers

  handleParentName(event) {
    this.setState({
      studentPhone: event.target.value,
    });
  }
  handleParentEmail(event) {
    this.setState({
      studentPhone: event.target.value,
    });
  }
  handleParentPhone(event) {
    this.setState({
      studentPhone: event.target.value,
    });
  }

  render() {
    return (
      <fieldset>
        <Form.Group as={Row} style={{ padding: "1em" }}>
        

          <Form.Label>
            <strong>Student Information</strong> *Note: must complete all fields
          </Form.Label>
          <Col xs={10} sm={7}>
            <Form.Control
              type="text"
              name="studentName"
              placeholder="Enter Student Name "
              value={this.props.studentName}
              onChange={this.props.handleChange}
            />
            <Form.Control
              type="text"
              name="studentEmail"
              placeholder="Enter Student Email"
              value={this.props.studentEmail}
              onChange={this.props.handleChange}
            />
            <Form.Control
              type="text"
              name="studentPhone"
              placeholder="Enter Student Phone "
              value={this.props.studentPhone}
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} style={{ padding: "1em" }}>
          <Form.Label>
            {" "}
            <strong>Parent Information </strong> *Note: must complete all fields
          </Form.Label>
          <Col xs={10} sm={7}>
            <Form.Control
              type="text"
              name="parentName"
              placeholder="Enter Parent Name "
              value={this.props.parentName}
              onChange={this.props.handleChange}
            />
            <Form.Control
              type="text"
              name="parentEmail"
              placeholder="Enter Parent Email "
              value={this.props.parentEmail}
              onChange={this.props.handleChange}
            />
            <Form.Control
              type="text"
              name="parentPhone"
              placeholder="Enter Parent Phone"
              value={this.props.parenttPhone}
              onChange={this.props.handleChange}
            />
          </Col>
        </Form.Group>
      </fieldset>
    );
  }
}
export default PersonalData;
