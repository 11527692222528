// Menu.styled.js
import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  width: 100%;

  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  background: black;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100%;
  text-align: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;

  div {
    font-size: 20px;
    color: white;
    transition: color 0.2s linear;
    &:hover {
      background: red;
      color: black;
    }
  }
`;
