import React from "react";
import { Container } from "react-bootstrap";
import TabSection from "../program-overview/po-tabs/tabs";

class ServicesSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabinfo: {
        sectionTabContentClassName: "eligibility-tabs",
        descriptions: [
          {
            title: " Two Major Components",
            tid: "two-major-components-tab",
            button: "two-major-components-button",
            text: (
              <div>
                <p>The program offers two specific components:</p>
                <ul>
                  <li>
                    <p>
                      <b>Academic Year Session (Sept. to June):</b> Saturday
                      Academy includes but is not limited to: assistance in
                      choosing a college; tutoring; personal and financial
                      counseling; career counseling; assistance in applying to
                      college; special instruction in reading, writing, study
                      skills, and mathematics; assistance in applying for
                      financial aid; SAT preparation; leadership development
                      workshops; college tours; and exposure to enrichment
                      engagement. Annual academic activities/events also include
                      after school tutoring, academic advisement, socio
                      emotional and social justice workshops and STEM
                      opportunities
                    </p>
                  </li>
                  <li>
                    <p>
                      {" "}
                      <b>Six Week Summer Session (June to Aug.):</b> Students
                      participate in an authentic “college going experience” by
                      residing on a local college campus and taking
                      college-level courses. The intensity of the summer program
                      is designed to enhance each student’s abilities inside and
                      outside of the classroom and to prepare students for how
                      to successfully navigate the rigors of college. Students
                      have the opportunity to take part in a variety of academic
                      classes such as English Composition & Literature &
                      Composition, Foreign Language, Math, and Laboratory
                      Science as well as innovative STEM programming. Following
                      the completion of the summer component, an annual
                      excursion to visit colleges in Northern California
                      punctuates the six weeks.{" "}
                    </p>
                  </li>
                </ul>
                <p>
                  As mandated by Congress, two-thirds of the students served
                  must come from families with incomes under $37,650, where
                  neither parent graduated from college.
                </p>
              </div>
            ),
          },
          {
            title: "Eligibility",
            tid: "eligibility-tab",
            button: "eligibility-button",
            text: (
              <div>
                <p>
                  Approximately 70 students participate in the LBCC Upward Bound
                  Program. To be considered for admissions to the program you
                  must:
                </p>
                <ul>
                  <li>
                    Be potentially the first generation of your family to attend
                    college or qualify as income-dependent based on guidelines
                    from the US Dept. of Education.
                  </li>
                  <li>
                    Be a US citizen or permanent resident of the United States.
                  </li>
                  <li>
                    Be enrolled at one of the following Long Beach Unified
                    School District high schools: Cabrillo High School, Long
                    Beach Polytechnic High School, or Wilson High School.
                  </li>
                  <li>
                    Demonstrate the potential and desire to enter and
                    successfully complete a post-secondary education.
                  </li>
                </ul>
                <p>
                  Submission of the following materials is also required for
                  eligibility to the program:
                </p>
                <ul>
                  <li>Completed application and essay</li>
                  <li>
                    A copy of your birth certificate, social security card, or
                    permanent residency card
                  </li>
                  <li>Proof of family income</li>
                  <li>A copy of your high school transcripts</li>
                </ul>
              </div>
            ),
          },
        ],
      },
    };
  }
  render() {
    return (
      <Container>
        <div className="about-section-container">
          <h4>Services and Eligibility</h4>
          <p className="paragraph">
            The LBCC TRiO Upward Bound Program strives to develop in its
            participants’ strong academic skills; effective communication and
            leadership skills; creative and critical thinking skills; a positive
            self-image; a positive attitude toward learning; and respect and
            sensitivity for others. Participants also receive high school
            elective credits that count toward graduation.
          </p>
        </div>
        <div className="about-section-container">
          <TabSection tabinfo={this.state.tabinfo} />
          {/*  CONTENT FOR EACH TAB */}
          <div className="section-overview-tabcontent">
            {this.state.tabinfo.descriptions.map((item, index) => {
              return (
                <div
                  id={item.tid}
                  className={this.state.tabinfo.sectionTabContentClassName}
                  key={item.id}
                >
                  <div className="tab-container ">
                    <div className="tab-section">
                      <div className="tab-sub-heading">
                        <h4>{item.title}</h4>
                        <hr />
                      </div>
                      <div>{item.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    );
  }
}
export default ServicesSummary;
