import React from "react";

// styling
import "./icon-nav.css";

// import COTS
import {
  FaFacebookF,
  FaInstagram,
  FaDiscord,
  FaCalendar,
  FaWpforms,
  FaYoutube,
  FaLink,
  FaFolder,
} from "react-icons/fa";

// import implemented components
import * as Routes from "../../../constants/routes";
import FormController from "../../../pages/program-overview/form-controller";

const icons = [
  {
    name: "Facebook",
    icon: <FaFacebookF />,
    href: "https://www.facebook.com/LBCCUpwardBound/",
  },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    href: "https://www.instagram.com/lbcc_upward_bound/",
  },
  {
    name: "YouTube",
    icon: <FaYoutube />,
    href: "https://www.youtube.com/channel/UCLpp7-gaExdU1TubrkTi8yQ",
  },
  {
    name: "Discord",
    icon: <FaDiscord />,
    href: "https://discord.com/invite/5cBkagSzMH",
  },
  {
    name: "Calendar",
    icon: <FaCalendar />,
    href:
      "https://calendar.google.com/calendar/embed?src=upwardboundlbcc%40gmail.com&ctz=America%2FLos_Angeles",
  },
  {
    name: "Links",
    icon: <FaLink />,
    href: Routes.SERVICES + "/#links",
  },
  {
    name: "Resources",
    icon: <FaFolder />,
    href: Routes.SERVICES + "/#resources",
  },
  {
    name: "Eligibility Calculator",
    icon: <FormController />,
  },
  {
    name: "Join Today",
    icon: <FaWpforms />,
    href:
      "https://botform.compansol.com/202814728766868",
  },
];

class IconNav extends React.Component {
  render() {
    return (
      <div className="icon-nav-grid">
        {icons.map((item) => {
          return (
            <div className="icon-card">
              <a className="icon-area" href={item.href}>
                {" "}
                {item.icon}
              </a>
              <div className="name-area"> {item.name} </div>{" "}
            </div>
          );
        })}
      </div>
    );
  }
}

export default IconNav;
