//deal with duplicates
//if last name sort --> .last
// if by date --> dateCompare
// templating!!!
const heapify = (array, heapsize, i) => {
  let largest = i;
  let leftChildIndex = 2 * i + 1;
  let rightChildIndex = 2 * i + 2;
  if (
    leftChildIndex < heapsize &&
    new Date(array[leftChildIndex].date).getTime() >
      new Date(array[largest].date).getTime()
  ) {
    largest = leftChildIndex;
  }
  if (
    rightChildIndex < heapsize &&
    new Date(array[rightChildIndex].date).getTime() >
      new Date(array[largest].date).getTime()
  ) {
    largest = rightChildIndex;
  }
  if (largest !== i) {
    const temp = array[i];
    array[i] = array[largest];
    array[largest] = temp;
    heapify(array, heapsize, largest);
  }
};
const heapSort = (array) => {
  let size = array.length;
  for (let index = Math.floor(size / 2) - 1; index >= 0; index--) {
    heapify(array, size, index);
  }
  for (let index = size - 1; index >= 0; index--) {
    let x = array[0];
    array[0] = array[index];
    array[index] = x;
    heapify(array, index, 0);
  }
};

export default heapSort;
