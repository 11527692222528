import React from "react";
import trio from "../../assets/about/trio-logo.jpg";

import { Container, Row } from "react-bootstrap";

class Trio extends React.Component {
  render() {
    return (
      <Container style={{ padding: "2em 0" }}>
        <Row style={{ justifyContent: "center" }}>
          <a href="https://www2.ed.gov/programs/trioupbound/index.html">
            <img
              src={trio}
              style={{ height: "10vh", opacity: 0.8 }}
              alt="trio"
            ></img>
          </a>
        </Row>
      </Container>
    );
  }
}

export default Trio;
