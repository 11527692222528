import React from "react";

class SeniorCollegePadlet extends React.Component {
  render() {
    return (
      <div
        className="padlet-embed"
        style={{
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: "2px",
          boxSizing: "borderBox",
          overflow: "hidden",
          position: "relative",
          width: "100%",
          background: "#F4F4F4",
        }}
      >
        <p style={{ padding: "0", margin: "0" }}>
          <iframe
            title="ub-seniors-2021"
            src="https://padlet.com/embed/n5ukvcxskaw5kgsh"
            frameBorder="0"
            allow="camera;microphone;geolocation"
            style={{
              width: "100%",
              height: "100vh",
              display: "block",
              padding: "0",
              margin: "0",
            }}
          ></iframe>
        </p>
        <div style={{ padding: "8px", textAlign: "right", margin: "0" }}>
          <a
            href="https://padlet.com?ref=embed"
            style={{
              padding: "0",
              margin: "0",
              border: "none",
              display: "block",
              lineHeight: "1",
              height: "16px",
            }}
          >
            <img
              src="https://padlet.net/embeds/made_with_padlet.png"
              width="86"
              height="16"
              style={{
                padding: "0",
                margin: "0",
                background: "none",
                border: "none",
                display: "inline",
                boxShadow: "none",
              }}
              alt="Upward Bound Padlet"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default SeniorCollegePadlet;
