import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";

// import data
import { announcements } from "./announcements-info";

// import styling
import "./announce-carousel.css";

// import other components
import AnnouncementHeader from "../../../../components/styled-components/announcement.styled";

function Announcements() {
  const [index, setIndex] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    let date = new Date();
    // console.log("Date", date);
    let day = date.getDay();
    // console.log("Day: ", day);

    // console.log("Month: ", date.getMonth());
    // console.log("Year: ", date.getYear());
    let hour = date.getHours();
    // console.log("Hour: ", hour);

    // unlock conscious citizen button for that period of time between 5 -7
    // console.log("hour", hour);
    // console.log("day", day);

    if (16 < hour <= 19 && day === 3) {
      // console.log("In if statement 1");
      // console.log(document.getElementById("conscious-citizen-button"));
      // // document.getElementById("conscious-citizen-button").disabled = false;
      // console.log(document.getElementById("conscious-citizen-button").disabled);
      // console.log(document.getElementById("conscious-citizen-button"));
    }
    // if (14 < hour <= 18 && day === 5) {
    //   document.getElementById("friday-workshops").disabled = false;
    // }
  });

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {announcements.map((item) => {
        return (
          <Carousel.Item>
            <AnnouncementHeader img={item.image} className={item.headertype}>
              <h1 className="announcement-header">{item.header} </h1>
              <p className={item.contentStyle}>{item.content}</p>
              <div className="announcement-icons">{item.icons}</div>
            </AnnouncementHeader>

            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default Announcements;
