import { Carousel } from "react-bootstrap";
import { useState } from "react";
import React from "react";

import allImages from "./image-array";


export default function ImageViewer(props) {
  // console.log("properties of image containger:", props);
  const [propIndex] = useState(props.index);
  const [index, setIndex] = useState(0);
  const [carouseltype, setCarouseltype] = useState(props.carouseltype);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  let type = getType(propIndex);

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={7000}
      style={{ zIndex: "1" }}
    >
      {getImagesTo(propIndex).map((item) => {
        // console.log(item);
        return (
          <Carousel.Item>
            <img
              alt={item}
              src={require(`../../assets/${carouseltype}/${type}/${item}.jpg`)}
              style={{
                objectFit: "scale-down",
                height: "675px",
                width: "100%",
              }}
            ></img>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

function getImagesTo(index) {
  // console.log("Entering switch statement with index: ", index);
  switch (index) {
    case 0:
      return allImages[0].sectionImages;

    case 1:
      return allImages[1].sectionImages;

    case 2:
      return allImages[2].sectionImages;

    case 3:
      return allImages[3].sectionImages;

    case 4:
      return allImages[4].sectionImages;
    default:
      return;
  }
}

function getType(index) {
  switch (index) {
    case 0:
      return allImages[0].id;

    case 1:
      return allImages[1].id;

    case 2:
      return allImages[2].id;

    case 3:
      return allImages[3].id;

    case 4:
      return allImages[4].id;
    default:
      return;
  }
}
