import React, { useState } from "react";
import WebMenu from "./web-menu.jsx";
import Hamburger from "./hamburger-menu";
import trioLogo from "../../assets/logos/trio-logo.png";
import lbccLogo from "../../assets/logos/LBCC-logo.jpg";
import HeaderIconContainer from "../icon-container/header-icon-container";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Routes from "../../constants/routes";

import { Tooltip } from "react-bootstrap";

import {
  FaCalendar,
  FaLink,
  // FaVideo,
  FaWpforms,
  // FaYoutube,
  // FaInstagram,
  // FaFacebookF,
} from "react-icons/fa";
import "./menu-styling.css";

// import pages
import FormController from "../../pages/program-overview/form-controller";
import About from "../../pages/about/about-main.jsx";
import Events from "../../pages/events/event-main.jsx";
import ProgramOverview from "../../pages/program-overview/program-overview.jsx";
import TrueColors from "../../pages/surveys/true-colors/true-colors";
import StudentsMain from "../../pages/students/students-main-page";
import PadletNextGen from "../program-overview/padlet-next-gen/padlet-next-gen";

import IconNav from "../icon-container/icon-nav/icon-nav-container";

function IntegratedMenu() {
  const [open, setOpen] = useState(false);

  return (
    <Router>
      <nav className="navbar-grid" role="banner">
        {/* Check Window Size for TRIO and LBCC LOGOS */}
        <div className="navbar-logos"> {checkWindowSize(1)}</div>
        <div className="nav-logo-ub">{title()}</div>
        <div className="navbar-items"> {showHamburger(open, setOpen)}</div>
        {/* SOCIAL MEDIA ICONS */}
      </nav>
      <IconNav />

      <Switch>
        <Route path={Routes.TRUECOLORS} component={TrueColors} />
        <Route path={Routes.ABOUT} component={About}></Route>
        <Route path={Routes.LINKSTABLE} component={Events}></Route>
        <Route path={Routes.SERVICES} component={Events}></Route>
        <Route path={Routes.STUDENTS} component={StudentsMain}></Route>
        <Route path={Routes.NEXTGEN} component={PadletNextGen}></Route>
        <Route path={Routes.HOME} component={ProgramOverview}></Route>
      </Switch>

      <WebMenu open={open} setOpen={setOpen} />
    </Router>
  );
}

export default IntegratedMenu;

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props} style={{ whiteSpace: "pre-wrap" }}>
    {props}
  </Tooltip>
);

const checkWindowSize = (section) => {
  if (window.innerWidth > 750 && section === 1) {
    return logos();
  }
};
const title = () => {
  return (
    <a href="/">
      <div
        style={{
          color: "white",
          fontSize: "18px",
          textAlign: "center",
        }}
      >
        Upward Bound{" "}
      </div>
    </a>
  );
};
const logos = () => {
  return (
    <div className="logo-grid">
      <a
        href="https://www2.ed.gov/programs/trioupbound/index.html"
        className="nav-logo-trio"
      >
        <img src={trioLogo} style={{ height: "1em" }} alt="trio-upward-bound" />
      </a>
      <a href="https://www.lbcc.edu/upward-bound" className="nav-logo-lbcc">
        <img src={lbccLogo} style={{ height: "3em" }} alt="lbcc-logo" />
      </a>
    </div>
  );
};

const showHamburger = (open, setOpen) => {
  if (window.innerWidth < 750) {
    return <Hamburger open={open} setOpen={setOpen} />;
  } else {
    return (
      <div>
        <a href={Routes.HOME} className="navbar-item-style">
          Home
        </a>
        <a href={Routes.ABOUT} className="navbar-item-style">
          About
        </a>
        <a href={Routes.SERVICES} className="navbar-item-style">
          Services
        </a>
        <a href={Routes.STUDENTS} className="navbar-item-style">
          Students
        </a>
      </div>
    );
  }
};
