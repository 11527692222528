import React from "react";

// import COTS
import { Container, Row, Col } from "react-bootstrap";
import { FaUser, FaPhone, FaEnvelope } from "react-icons/fa";

// import styling
import "./about.css";

class Staff extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: [
        {
          role: "Upward Bound Program",
          name: "General",
          phone: "(562)938-3177",
          tel: "tel:5629383177",
          email: "upwardbound@lbcc.edu",
          mailto: "mailto:upwardbound@lbcc.edu",
        },
        {
          role: "TRiO Upward Bound Supervisor",
          name: "Wendy Porter-Coste",
          phone: "(562) 938-3176",
          tel: "tel:5629383176",
          email: "wporter-coste@lbcc.edu",
          mailto: "mailto:wporter-coste@lbcc.edu",
        },
        {
          role: "TRiO Upward Bound Program Specialist",
          name: "Elizabeth Morales",
          phone: "(562) 938-3188",
          tel: "tel:5629383188",
          email: "emorales@lbcc.edu",
          mailto: "mailto:emorales@lbcc.edu",
        },
        {
          role: "Senior Office Assistant",
          name: "Dora Ochoa",
          phone: "(562) 938-3127",
          tel: "tel:5629383127",
          email: "dochoa@lbcc.edu",
          mailto: "mailto:dochoa@lbcc.edu",
        },
      ],
    };
  }

  render() {
    return (
      <Container>
        <Row style={{ justifyContent: "center" }}>
          {this.state.staff.map((staffMember) => {
            return (
              <Col sm={12} lg={6}>
                <div className="staff-card" id={staffMember.name}>
                  <h4 className="staff-card-role"> {staffMember.role}</h4>
                  {/*--------------STAFF NAME --------------*/}
                  <div className="staff-card-person-icon staff-card-icon">
                    <FaUser />
                  </div>
                  <div className="staff-card-person staff-card-item">
                    {staffMember.name}
                  </div>
                  {/*-------------- STAFF EMAIL--------------*/}
                  <a
                    href={staffMember.mailto}
                    className="staff-card-email-icon staff-card-icon"
                  >
                    {" "}
                    <FaEnvelope />
                  </a>{" "}
                  <div className="staff-card-email staff-card-item">
                    {staffMember.email}
                  </div>
                  {/*--------------STAFF PHONE--------------*/}
                  <a
                    className=" staff-card-phone-icon  staff-card-icon"
                    href={staffMember.tel}
                  >
                    <FaPhone />
                  </a>
                  <div className="staff-card-phone staff-card-item">
                    {staffMember.phone}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>

      </Container>
    );
  }
}

export default Staff;
