import { FaCalendar, FaFilePdf, FaLink, FaWpforms } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import images
import selfcare from "../../../../assets/announcements/self-care.jpg";
import spring from "../../../../assets/announcements/spring.jpg";
import capitol from "../../../../assets/announcements/us-flag-1.jpg";
import conscious from "../../../../assets/announcements/flyer-conscious-citizen.png";
import calvin from "../../../../assets/announcements/calvin.jpg";
import colaberry from "../../../../assets/colaberry-3.jpg";

import * as Routes from "../../../../constants/routes";

function displayText(value) {
  if (window.innerWidth > 750) {
    return <div className="name-area"> {value} </div>;
  }
}

export const announcements = [
  {
    headertype: "announcement-header-grid",
    header: "Upward Bound Spring Services",
    content: (
      <div>
        <p>
          To see a detailed list of services with times and meeting links visit
          our services page.{" "}
        </p>
        <p>
          <ul>
            <li>Study Hall</li>
            <li>Evening Workshops</li>
            <li>Academic Accountability w/ Progarm Specialist</li>
            <li>Ubuntu Circle</li>
            <li>
              Technology Groups: Social Media Group, Backend Technology Group{" "}
            </li>
          </ul>
        </p>
      </div>
    ),
    icons: (
      <div className="icon-grid">
        {/* -----------------------workshop link-------------------------- */}
        <a href={Routes.SERVICES + "/#links"}>
          <button
            id="join-links"
            className="icon-button"
            style={{ gridArea: "icon1" }}
          >
            <div className="icon-card">
              <div className="icon-area">
                <FaLink color="white" />
              </div>
              {displayText("Links")}
            </div>
          </button>
        </a>
        {/* -----------------------flyer-------------------------- */}
        {/* <a
        className="icon-button"
        style={{ gridArea: "icon2" }}
        href="https://forms.gle/HDJ9KaZxQXz8CSJ3A"
      >
        <div className="icon-card">
          <div className="icon-area">
            <FaWpforms color="white" />
          </div>

          <div className="name-area">Take Survey</div>
        </div>
      </a> */}
        {/* -----------------------events-------------------------- */}
      </div>
    ),
    contentStyle: "announcement-content",

    image: spring,
  },
  // {
  //   headertype: "announcement-header-grid",
  //   header: "Upward Bound Ubuntu Circle",
  //   content: (
  //     <div>
  //       <p>
  //         {" "}
  //         Join us on Fridays as we learn more about the importance of self-care.
  //         We discuss practical steps on how to improve your awareness on
  //         self-care practices, how to get the most of self-care for you, and the
  //         stability that self-care brings into your daily life.
  //       </p>
  //       <p>
  //         Let us know how we can help you get a better understanding on the
  //         importance of self-care by taking the survey below.
  //       </p>
  //       <p>
  //         <div>
  //           {" "}
  //           <strong>When:</strong> Fridays
  //         </div>
  //         <div>
  //           {" "}
  //           <strong>Time:</strong> 4:00pm -5:15pm
  //         </div>
  //       </p>
  //     </div>
  //   ),
  //   contentStyle: "announcement-content",

  //   icons: (
  //     <div className="icon-grid">
  //       {/* -----------------------workshop link-------------------------- */}
  //       <button
  //         id="friday-workshops"
  //         className="icon-button"
  //         style={{ gridArea: "icon1" }}
  //         onClick={(e) => {
  //           e.preventDefault();
  //           window.location.href =
  //             "https://us02web.zoom.us/j/81071595871?pwd=cXNoMGFKc1I5YWNramNhUnlUN2dBUT09";
  //         }}
  //       >
  //         <div className="icon-card">
  //           <div className="icon-area">
  //             <FaLink color="white" />
  //           </div>

  //           {displayText("Join Workshop")}
  //         </div>
  //       </button>
  //       {/* -----------------------flyer-------------------------- */}
  //       <a
  //         className="icon-button"
  //         style={{ gridArea: "icon2" }}
  //         href="https://forms.gle/HDJ9KaZxQXz8CSJ3A"
  //       >
  //         <div className="icon-card">
  //           <div className="icon-area">
  //             <FaWpforms color="white" />
  //           </div>
  //           {displayText("Take Survey")}
  //         </div>
  //       </a>
  //       {/* -----------------------events-------------------------- */}

  //       <a className="icon-button" href={Routes.SERVICES + "/#links"}>
  //         <div className="icon-card">
  //           <div className="icon-area">
  //             <FaCalendar color="white" />
  //           </div>
  //           {displayText("Calendar")}
  //         </div>
  //       </a>
  //     </div>
  //   ),
  //   image: selfcare,
  // },
];
