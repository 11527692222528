import React from "react";

// import styling
import "./elt-info.css";

// import data
import { elts } from "./elt-info";
// import COTS
import { Container, Row } from "react-bootstrap";

//styled components for theme
import SectionHeader from "../../../components/program-overview/section-header";
import SectionDescription from "../../../components/program-overview/section-description";

class ElTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: 0,
      eltData: elts,
    };
  }

  findEltImage() {}
  updateSection(e, index) {
    e.preventDefault();
    console.log("Updating Section ID", index);
    this.setState({
      currentId: index,
    });
  }
  render() {
    return (
      <div>
        <div className="page-section-layout" style={{ height: "fit-content" }}>
          <div className="page-section-menu">
            {this.state.eltData.map((item, index) => {
              return (
                <div
                  className="page-section-menu-item"
                  onClick={(e) => this.updateSection(e, index)}
                  key={index}
                >
                  {item.name}
                </div>
              );
            })}
          </div>

          <Container
            fluid
            className="page-section-display"
            style={{ height: "fit-content" }}
          >
            <Row id={this.state.eltData[this.state.currentId].id}>
              <SectionHeader>
                {" "}
                <h2 style={{ fontWeight: "bold" }}>
                  Meet {this.state.eltData[this.state.currentId].name}
                </h2>
              </SectionHeader>
            </Row>
            <Row>
              <SectionDescription>
                <div className="elt-card-container">
                  <div className="elt-card">
                    {/* <div className="elt-card-name">
                      {" "}
                      {this.state.eltData[this.state.currentId].name}
                    </div> */}

                    <div className="elt-image-container">
                      <img
                        src={
                          this.state.eltData[this.state.currentId].image
                            .portrait
                        }
                        className="elt-image"
                        alt={this.state.eltData[this.state.currentId].image.alt}
                      ></img>
                    </div>
                    <div className="elt-card-intro">
                      {" "}
                      <div>
                        {this.state.eltData[this.state.currentId].intro}
                      </div>
                      <div>
                        {" "}
                        {this.state.eltData[this.state.currentId].funfact}
                      </div>
                      <div>
                        {" "}
                        {this.state.eltData[this.state.currentId].tags.map(
                          (item, index) => {
                            return (
                              <div
                                style={{
                                  display: "inline",
                                  padding: "0 1em 0 0",
                                }}
                                key={index}
                              >
                                {item}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SectionDescription>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default ElTeam;
