import React from "react";
import { FaInstagram, FaYoutube, FaFacebookF } from "react-icons/fa";
import "./icon-container.css";

export default class SectionIconContainer extends React.Component {
  render() {
    return (
      <div className="section-container">
        <h3 className="section-message">
          {" "}
          Learn More About Us On Social Media
        </h3>

        <a
          href="https://www.instagram.com/lbcc_upward_bound/"
          className=" section-icon-insta section-icon"
        >
          <FaInstagram size={45} />
        </a>

        <a
          href="https://www.youtube.com/channel/UCLpp7-gaExdU1TubrkTi8yQ"
          className=" section-icon-youtube section-icon"
        >
          <FaYoutube size={45} />
        </a>

        <a
          href="https://www.facebook.com/LBCCUpwardBound/"
          className=" section-icon-face section-icon"
        >
          <FaFacebookF size={50}></FaFacebookF>
        </a>
      </div>
    );
  }
}
