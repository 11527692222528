import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

import "./gen-video-format.css";
import updates from "../../../../assets/program-overview/header/airplane.jpg";
// import plants from "../../../../assets/program-overview/header/plants.jpg";
// import colaberry from "../../../../assets/program-overview/header/colaberry-workshop.jpg";
// import Header from "../../../../components/styled-components/header.styled";

// import nextGen from "../../../../assets/program-overview/next-gen.jpg";
// import * as Routes from "../../../../constants/routes";

// main page header containing the first generation links to padlet
class VideoFormat extends React.Component {
  render() {
    return (
      <div className="header-video-grid">
        <div className="vg-video">
          <iframe
            title="who-we-are-2020"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/5wEMQUbj_Gc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div
          className="vg-updates"
          style={{
            backgroundImage: `url(${updates}) `,
          }}
        >
          <div
            className="vg-update-1"
            // style={{
            //   backgroundImage: `url(${plants}) `,
            //   backgroundSize: "cover",
            //   backgroundColor: "rgba(245, 245, 245,0.5)",
            //   backgroundBlendMode: "overlay",
            // }}
          >
            <div className="vg-update-card">
              <div className="vg-update-card-title">
                {" "}
                <h5 style={{ fontWeight: "bold" }}>
                  Who We Are + I Am Enough Poem
                </h5>
              </div>
              <div className="vg-update-card-external">
                {" "}
                <a href="https://www.youtube.com/watch?v=FafLwXJMcps">
                  <FaExternalLinkAlt color="red" />
                </a>
              </div>
              <div className="vg-update-card-summary">
                Watch the extended version of the "Who We Are" video that
                includes a poem composed by our students expressing their Ubuntu
                perspective and values and how they felt empowered. #IAmEnough
              </div>
              <div className="vg-update-card-hashtags">
                <div> #Ubuntu #IAmBecauseWeAre #WeAreEnough #IAmEnough</div>
              </div>
            </div>
          </div>
          <div
            className="vg-update-2"
            // style={{
            //   backgroundImage: `url(${nextGen}) `,
            //   backgroundSize: "cover",
            //   backgroundColor: "rgba(245, 245, 245,0.5)",
            //   backgroundBlendMode: "overlay",
            // }}
          >
            <div className="vg-update-card">
              <div className="vg-update-card-title">
                {" "}
                <h5 style={{ fontWeight: "bold" }}>Next Generation Campaign</h5>
              </div>
              <div className="vg-update-card-external">
                {" "}
                <a href="https://padlet.com/upwardboundlbcc/squcrgtqo0bhogjw">
                  <FaExternalLinkAlt color="red" />
                </a>{" "}
              </div>
              <div className="vg-update-card-summary">
                Celebrate our first generation students by participating in our
                padlet that showcases the accomplishments of our students being
                the First in college, the First Business Owner, the First
                Teacher, you name it.
              </div>
              <div className="vg-update-card-hashtags">
                {" "}
                <div> #UpwardBoundLBCCNextGen #UpwardBoundLBCCFirstGen</div>
              </div>
            </div>
          </div>
          <div
            className="vg-update-3"
            // style={{
            //   backgroundImage: `url(${colaberry}) `,
            //   backgroundSize: "cover",
            //   backgroundColor: "rgba(245, 245, 245,0.5)",
            //   backgroundBlendMode: "overlay",
            // }}
          ></div>
        </div>
      </div>
    );
  }
}
export default VideoFormat;
